import React from 'react';

import axios from 'axios';
import Raven from 'raven-js';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { AxiosRequest } from './components/AxiosRequest';
import { LoginForm } from './components/LoginForm';
import { NoopComponent } from './components/NoopComponent';
import { store } from './store';
import { changeUserOriginAction } from './store/reducers/poolVariables';
import { UserType } from './types';
import { registerAxiosInterseptors } from './utils';
import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

if (process.env.NODE_ENV === 'production') {
  Raven.config('https://9f84eb8e1cf54326ab4c742cebda4bd4@sentry.io/1283174').install();
}

registerAxiosInterseptors();

const getCookie = (name: string) => {
  const value = '; ' + window.document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const setCookie = (name: string, value: string) => {
  window.document.cookie = `${name}=${value}; path=/`;
};

const deleteCookie = (name: string) => {
  window.document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

const setAxiosDefaultAuthorizationHeader = (jwt: string) => {
  axios.defaults.headers.common['X-Authorization'] = jwt;
};

const deleteAxiosDefaultAuthorizationHeader = () => {
  delete axios.defaults.headers.common['X-Authorization'];
};

const COOKIE_PHOENIX_TOKEN = 'phoenix-jwt';
const cookie = getCookie(COOKIE_PHOENIX_TOKEN);

if (typeof cookie === 'string') {
  setAxiosDefaultAuthorizationHeader(cookie);
}

const Main = ({ user }: { user?: UserType }) => {
  const [ userState, setUserState ] = React.useState(user);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (userState) dispatch(changeUserOriginAction(userState.originId));
    //eslint-disable-next-line
  }, [user]);

  const onLogin = (user: UserType) => {
    setCookie(COOKIE_PHOENIX_TOKEN, user.jwt);
    setAxiosDefaultAuthorizationHeader(user.jwt);
    setUserState(user);
  };

  const logout = () => {
    if (!userState) {
      return;
    }
    axios.post('/v1/DealerAPI/logout', {
      jwt: user.jwt,
    });
    deleteCookie(COOKIE_PHOENIX_TOKEN);
    deleteAxiosDefaultAuthorizationHeader();
    setUserState(null);
  };

  if (!userState) return <LoginForm onLogin={onLogin} />;

  return <App username={userState.username} logout={logout} />;
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AxiosRequest
        noError
        Loading={NoopComponent}
        config={cookie ? '/v1/DealerAPI/tokenValidate' : null}
      >
        {({ data, error }) => {
          const user = data && !error ? data.result : null;
          return <Main user={user} />;
        }}
      </AxiosRequest>
    </Provider>
  </BrowserRouter>
);

// * HMR
//@ts-ignore
if (module.hot) {
  //@ts-ignore
  module.hot.accept();
}
