import React from 'react';

import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import { sortingPoolList } from './helpers';
import { IpoolsTableRow } from './IpoolsTableRow';
import { NoDataComponent } from '../../components/NoDataComponent';
import { ASCENDING, DESCENDING } from '../../constants';
import {
  IpoolsColumnLabelEnum,
  IpoolsTableSortByEnum,
  IpoolsTableSortingState,
  ReduxStateType
} from '../../types';

const COLUMNS = [
  IpoolsColumnLabelEnum.Id,
  IpoolsColumnLabelEnum.Name,
  IpoolsColumnLabelEnum.Status,
  IpoolsColumnLabelEnum.Origins,
  IpoolsColumnLabelEnum.BettingStartTime,
  IpoolsColumnLabelEnum.BettingEndTime,
  IpoolsColumnLabelEnum.SettlementTime,
  IpoolsColumnLabelEnum.Created,
  IpoolsColumnLabelEnum.LastUpdated,
  IpoolsColumnLabelEnum.Empty
];

export const IpoolsTable = () => {
  const state = useSelector((state: ReduxStateType) => state);
  const { poolList } = state.poolTable;

  const [ sortingState, setSortingState ] = React.useState<IpoolsTableSortingState>({
    column: null,
    direction: DESCENDING,
  });

  const sortedPoolsList = sortingPoolList(sortingState, poolList);

  function sortByFunc(column) {
    setSortingState(state => ({
      column,
      direction: state.direction === ASCENDING ? DESCENDING : ASCENDING,
    }));
  }

  return (
    <Table sortable singleLine size="small" compact="very">
      <Table.Header>
        <Table.Row>
          {COLUMNS.map((column, index) => {
            const sortByEnum = IpoolsTableSortByEnum[column];
            function onClick() {
              if (!sortByEnum) return;
              sortByFunc(sortByEnum);
            }

            return (
              <Table.HeaderCell
                key={column + index}
                sorted={sortingState.column === sortByEnum ? sortingState.direction : null}
                onClick={onClick}
              >
                {column}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {!sortedPoolsList.length ? (
          <NoDataComponent />
        ) : (
          <>
            {sortedPoolsList.map(pool => (
              <IpoolsTableRow key={pool.id} pool={pool} />
            ))}
          </>
        )}
      </Table.Body>
    </Table>
  );
};
