import React from 'react';

import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Grid, Segment, Header } from 'semantic-ui-react';

import { ReduxStateType } from '../../types';
import './PoolStatistic.scss';

export const PoolStatisticSegment = () => {
  const {
    status,
    entryBet,
    commission,
    currencyCode,
    prizeFundGuarantee,
    prizeJackpot,
    prizeBonusIndividual,
    prizeBonusTotal,
    insuranceCommission: insurance,
  } = useSelector((state: ReduxStateType) => state.poolInfo);

  const {
    takenPart,
    totalMoneyBet,
    commission: statisticCommission,
    insuranceCommission,
    operatorFundShortfall,
    entriesTotal,
    prizeFund,
    prizeFundDistribution,
    jackpotPaid,
    roundingTotal,
    prizeBonusPaid,
    prizeBonusLeft,
  } = useSelector((state: ReduxStateType) => state.poolStatistic);

  const statisticClasses = cx('pool-page-statistic', {
    'pool-page-statistic_disable': status === 'DRAFT' || status === 'AVAILABLE' || status === 'VOIDED',
  });

  return (
    <article className="pool-page-statistic-wrapper">
      <Segment placeholder className={statisticClasses}>
        <Grid>
          <Grid.Column className="pool-page-statistic-column" width={4}>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_bold">
              <Header sub>Users</Header>
              <span>{takenPart ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_bold">
              <Header sub>Total money bet</Header>
              <span>{totalMoneyBet ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_bold">
              <Header sub>Commission earned</Header>
              <span>{statisticCommission?.total ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_bold">
              <Header sub>Insurance commission</Header>
              <span>{insuranceCommission?.total ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_bold">
              <span className={cx({ 'pool-page-statistic-cell_disable': !operatorFundShortfall })}>
                Fund the shortfall
              </span>
            </section>
          </Grid.Column>

          <Grid.Column className="pool-page-statistic-column" width={3}>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_bold">
              <Header sub>Entries</Header>
              <span>{entriesTotal ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell">
              <Header sub>Entry bet</Header>
              <span>{entryBet ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell">
              <Header sub>Commission</Header>
              <span>{commission ?? '-'}%</span>
            </section>
            <section className="pool-page-statistic-cell">
              <Header sub>Insurance</Header>
              <span>{insurance ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell">
              <Header size="tiny">Guarantee</Header>
              <span>{prizeFundGuarantee ?? '-'}</span>
            </section>
          </Grid.Column>

          <Grid.Column className="pool-page-statistic-column" width={3}>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_bold">
              <Header sub>Prize fund</Header>
              <span>{prizeFund ?? '-'}</span>
            </section>

            {prizeFundDistribution.map((place, index) => (
              <section key={index} className="pool-page-statistic-cell pool-page-statistic-cell_bold">
                <Header sub>{index + 1}place</Header>
                <span>
                  {place?.total ?? '-'} ({place?.value ?? '-'}%)
                </span>
              </section>
            ))}
          </Grid.Column>

          <Grid.Column className="pool-page-statistic-column" width={3}>
            <section className="pool-page-statistic-cell">
              <Header sub>Origin currency</Header>
              <span>{currencyCode ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_bold">
              <Header sub>Jackpot paid</Header>
              <span>{jackpotPaid ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell">
              <Header sub>Jackpot</Header>
              <span>{prizeJackpot ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell">
              <Header sub>Rounding total</Header>
              <span>{roundingTotal ?? '-'}</span>
            </section>
          </Grid.Column>

          <Grid.Column className="pool-page-statistic-column" width={3}>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_opacity">
              <Header sub>Bonus paid</Header>
              <span>{prizeBonusPaid ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_bold">
              <Header sub>Bonus paid</Header>
              <span>{prizeBonusPaid ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell pool-page-statistic-cell_bold">
              <Header sub>Bonus left</Header>
              <span>{prizeBonusLeft ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell">
              <Header sub>Individual bonus</Header>
              <span>{prizeBonusIndividual ?? '-'}</span>
            </section>
            <section className="pool-page-statistic-cell">
              <Header sub>Bonus total</Header>
              <span>{prizeBonusTotal ?? '-'}</span>
            </section>
          </Grid.Column>
        </Grid>
      </Segment>
    </article>
  );
};
