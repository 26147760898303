import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import {
  API_ENTRIES,
  API_FIND_BY_ID,
  API_POOL_STATISTICS,
  POOL_STATUS_ALL,
  POOL_STATUS_ALL_DESCRIPTION
} from './constants';
import {
  changeBonusSelectorAction,
  changeJackpotSelectorAction,
  changePoolEntriesAction,
  changeVoidSelectorAction
} from './store/reducers/poolEntries';
import { changePoolInfoAction } from './store/reducers/poolInfo';
import { changePoolStatisticAction } from './store/reducers/poolStatistic';

export const registerAxiosInterseptors = () => {
  axios.interceptors.response.use(
    response => {
      const { config, data } = response;

      if (
        data &&
        data.error &&
        typeof data.error.code === 'number' &&
        typeof data.error.error === 'string'
      ) {
        toast.error(data.error.error);

        return Promise.reject(
          new AxiosError(data.error.error, null, response.config, response.request, response)
        );
      }

      let filterName = '';

      if (config && config.url) {
        const arrUrl = config.url.split('/');
        filterName = arrUrl[2];
      }

      if (data && typeof data === 'object') {
        const { code, error } = data;
        if (code && error) {
          toast.error(`${filterName}: can't load. Error code: ${String(code)} "${String(error)}"`, {});
          // eslint-disable-next-line no-console
          console.log(data);

          return Promise.reject(
            new AxiosError(error, null, response.config, response.request, response)
          );
        }
      }

      return response;
    },
    error => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      const { response, request, config } = error;
      let filterName = '';

      if (config && config.url) {
        const arrUrl = config.url.split('/');

        filterName = arrUrl[3];
      }

      if (response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { status } = response;

        if (status >= 500) {
          if (filterName === 'find') {
            toast.error(`${filterName}: can't load. No response from server. Error code: ${status}`, {});
          }
        } else {
          if (response.data && response.data.error) {
            toast.error(`${response.data.error}`, {});
          } else {
            toast.error(`${filterName}: can't load. Error code: ${status}`, {});
          }
        }
      } else if (request) {
        // The request was made but no response was received
        toast.error(`${filterName}: can't load. You are offline`, {});
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error(`${filterName}: wrong request to server`, {});
      }

      return Promise.reject(error);
    }
  );
};

export const capitalize = stringToConvert =>
  stringToConvert[0].toUpperCase() + stringToConvert.slice(1);

export const getStartDate = () => {
  const startDate = new Date();
  startDate.setTime(startDate.getTime() + 15 * 60 * 1000);
  return startDate;
};

export const switchDescription = (arrOfTranslations, actualLanguage) =>
  arrOfTranslations.filter(el => el.lang === actualLanguage)[0]?.text;

export const createAvailableLanguages = array =>
  array.map(el => {
    if (el.lang === 'en') {
      return {
        key: el.lang,
        text: 'English',
        value: el.lang,
      };
    }
    if (el.lang === 'it') {
      return {
        key: el.lang,
        text: 'Italian',
        value: el.lang,
      };
    }
    if (el.lang === 'es') {
      return {
        key: el.lang,
        text: 'Spanish',
        value: el.lang,
      };
    }
    if (el.lang === 'ru') {
      return {
        key: el.lang,
        text: 'Russian',
        value: el.lang,
      };
    }
    if (el.lang === 'de') {
      return {
        key: el.lang,
        text: 'Deutsch',
        value: el.lang,
      };
    }
    if (el.lang === 'pt') {
      return {
        key: el.lang,
        text: 'Portuguese',
        value: el.lang,
      };
    }
    if (el.lang === 'pl') {
      return {
        key: el.lang,
        text: 'Polish',
        value: el.lang,
      };
    }
    if (el.lang === 'sv') {
      return {
        key: el.lang,
        text: 'Swedish',
        value: el.lang,
      };
    }
    return null;
  });

export const updateEntreStatus = ({ activeRows, action, dispatch }) => {
  switch (action) {
    case 'BONUS':
      dispatch(changeBonusSelectorAction(activeRows));
      break;
    case 'JACKPOT':
      dispatch(changeJackpotSelectorAction(activeRows));
      break;
    case 'VOID':
      dispatch(changeVoidSelectorAction(activeRows));
      break;
    default:
      return null;
  }
};

export const updatePoolInfo = ({ poolId, dispatch }) => {
  axios
    .get(API_FIND_BY_ID, { params: { id: poolId }})
    .then(response => response.data)
    .then(data => dispatch(changePoolInfoAction(data.result)))
    .catch(error => toast.error(error));
};

export const updatePoolStatistic = ({ poolId, dispatch }) => {
  axios
    .get(API_POOL_STATISTICS, { params: { poolId: poolId }})
    .then(response => response.data)
    .then(data => dispatch(changePoolStatisticAction(data.result)))
    .catch(error => toast.error(error));
};

export const updatePoolEntries = ({ poolId, dispatch }) => {
  axios
    .get(API_ENTRIES, { params: { poolId: poolId }})
    .then(response => response.data)
    .then(data => dispatch(changePoolEntriesAction(data.result)))
    .catch(error => toast.error(error));
};

export const formattingDate = (date: string, formatDate: string) => {
  if (!date) return '-';
  return format(Date.parse(date), formatDate);
};

export const getOptionText = (str: string) => {
  if (str === POOL_STATUS_ALL) return POOL_STATUS_ALL_DESCRIPTION;
  const result = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return result.replace(/_/gm, ' ');
};
