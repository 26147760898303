import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Dropdown, Modal } from 'semantic-ui-react';

import { FeedQuestion } from './FeedQuestions/FeedQuestion';
import { useAxiosRequest } from '../../../components/use-axios-request/use-axios-request.es';
import {
  API_QUESTION_ADD,
  BUTTONS_DEFAULT,
  BUTTONS_MULTIPLY,
  BUTTONS_SIMPLE,
  POOL_STATUS_ENUM
} from '../../../constants';
import {
  changeDateFromSearchAction,
  defaultSearchState
} from '../../../store/reducers/searchStateFeedQuestions';
import { ReduxStateType } from '../../../types';
import { getStartDate, updatePoolInfo } from '../../../utils';

export const QuestionsButtonPanel = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { status, id: poolId, isPoolWithMultiplyChoice, questions } = state.poolInfo;

  const [ open, setOpen ] = React.useState(false);

  const dropdownItems = getButtons();
  const isDraft = status === POOL_STATUS_ENUM.DRAFT;

  const { update } = useAxiosRequest(null, {
    onSuccess: () => {
      updatePoolInfo({ poolId, dispatch });
      toast.success('Manual question added successfully');
    },
  });

  const handleChange = React.useCallback(
    (e, { value }) => {
      update({
        method: 'POST',
        url: API_QUESTION_ADD,
        data: {
          poolId,
          questionType: value,
        },
      });
    },
    [ poolId, update ]
  );

  const onCloseModal = React.useCallback(() => {
    setOpen(false);
    updatePoolInfo({ poolId, dispatch });
    dispatch(defaultSearchState());
  }, [ dispatch, poolId ]);

  const onOpenModal = () => {
    dispatch(changeDateFromSearchAction(getStartDate()));
    setOpen(true);
  };

  function getButtons() {
    if (questions.length === 0) {
      return BUTTONS_DEFAULT;
    } else if (isPoolWithMultiplyChoice) {
      return BUTTONS_MULTIPLY;
    } else {
      return BUTTONS_SIMPLE;
    }
  }

  return (
    <div className="question-button-panel">
      <Modal
        closeIcon
        id="modal"
        className="feed-question-modal"
        onClose={onCloseModal}
        onOpen={onOpenModal}
        open={open}
      >
        <Modal.Content image>
          <FeedQuestion />
        </Modal.Content>
      </Modal>

      {!isPoolWithMultiplyChoice && (
        <Button
          labelPosition="left"
          icon="plus"
          content="Feed"
          disabled={!isDraft}
          onClick={onOpenModal}
        />
      )}
      <Dropdown
        text="Manual"
        icon="chevron down"
        floating
        labeled
        button
        className="icon"
        additionPosition="bottom"
        disabled={!isDraft}
      >
        <Dropdown.Menu>
          {dropdownItems.map(item => (
            <Dropdown.Item key={item.type} onClick={handleChange} value={item.type}>
              {item.description}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
