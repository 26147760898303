import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

export const getOptions = () => ({
  blockStyleFn: block => {
    const type = block.getType();
    if (type === 'blockquote') {
      return {
        element: 'blockquote',
        attributes: {
          className: 'ipools-description_highlight',
        },
      };
    }
    if (type === 'unstyled') {
      return {
        element: 'p',
      };
    }
  },
});

export const replaceValue = (str: string) =>
  str
    .replace(/<li>&nbsp;/gm, '<li>')
    .replace(/&nbsp;<\//gm, '</')
    .replace(/<p><br><\/p>|<p>&nbsp;<\/p>/gm, '')
    .replace(/<blockquote class="ipools-description_highlight">&nbsp;<\/blockquote>/gm, '')
    .replace(/<blockquote class="ipools-description_highlight"><br><\/blockquote>/gm, '')
    .replace(/<li>(<br>\s*)*<\/li>/gm, '')
    .replace(/<ul>\s*<\/ul>/gm, '')
    .replace(/<ol>\s*<\/ol>/gm, '')
    .replace(/\n^\s+\n/gm, '');

export const htmlToDraftBlocks = (html: string) => {
  const draftContent = stateFromHTML(html);
  return EditorState.createWithContent(draftContent);
};
