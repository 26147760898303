import { ASCENDING, POOL_STATUS_ENUM } from '../../constants';
import { IpoolsTableSortByEnum } from '../../types';

export function sortingPoolList(sortingState, poolsList) {
  if (sortingState.column === IpoolsTableSortByEnum.Id) {
    const sortingPoolsList = [ ...poolsList ];
    sortingPoolsList.sort((a, b) =>
      sortingState.direction === ASCENDING ? Number(a.id) - Number(b.id) : Number(b.id) - Number(a.id)
    );
    return sortingPoolsList;
  } else return poolsList;
}

export function getStatus(poolStatus) {
  let status = poolStatus || '';
  if (poolStatus === POOL_STATUS_ENUM.SETTLE_IN_PROGRESS) {
    status = 'Settle in progress';
  } else if (poolStatus === POOL_STATUS_ENUM.SETTLED_WITH_ERRORS) {
    status = 'Settle with error';
  }
  return status;
}
