import React from 'react';

import { Button, Form, Grid, Header, TextArea } from 'semantic-ui-react';

import {
  ADD_BUTTON,
  DATEPICKER,
  DROPDOWN,
  DROPDOWN_MULTIPLY,
  INPUT,
  INPUT_WITH_DELETE,
  TEXT_AREA,
  TEXT_EDITOR,
  TOGGLE
} from '../../constants';
import { CreationFormDictType } from '../../types';
import { DropdownMultiply } from '../DropdownMultiply';
import { FilterDatePicker } from '../FilterDatePicker';
import { TextEditor } from '../text-editor/TextEditor';

const ComponentByType = {
  dropdown: Form.Dropdown,
  input: Form.Input,
  datepicker: FilterDatePicker,
  toggle: Form.Checkbox,
  addButton: Button,
  textArea: TextArea,
  dropdownMultiply: DropdownMultiply,
  [INPUT_WITH_DELETE]: Form.Input,
  TEXT_EDITOR: TextEditor,
};

export const CreationFormField = ({
  label,
  type,
  options,
  name,
  handleChange,
  inputWidth = 12,
  labelWidth,
  disabled = false,
  id,
  isInPercents = false,
  error,
  value,
  checked,
  deleteInput,
  deleteWidth,
  formState,
  setFormState,
  setBackToPool,
}: {
  label: string;
  type: string;
  options?: Array<{
    key: string;
    text: string;
    value: string;
  }>;
  name: string;
  handleChange?: any;
  inputWidth?: number;
  labelWidth?: number;
  disabled?: boolean;
  id?: number;
  isInPercents?: boolean;
  error?: boolean;
  value?: any;
  checked?: boolean;
  deleteInput?: (id: number) => void;
  deleteWidth?: number;
  formState?: CreationFormDictType;
  setFormState?: (CreationFormDictType) => void;
  setBackToPool?: (boolean) => void;
}) => {
  const FormComponent = ComponentByType[type];
  return (
    <Grid verticalAlign={'middle'}>
      <Grid.Row columns={2}>
        <Grid.Column
          // @ts-ignore
          width={labelWidth || 16 - inputWidth}
          className={'field-label-wrapper'}
        >
          {' '}
          {type !== TOGGLE && (
            <Header className="field-label" textAlign="center" as="h5">
              {label}
            </Header>
          )}
        </Grid.Column>
        <Grid.Column
          // @ts-ignore
          width={inputWidth}
        >
          {type === TEXT_EDITOR && (
            <TextEditor
              value={value}
              name={name}
              formState={formState}
              setFormState={setFormState}
              setBackToPool={setBackToPool}
              error={error}
            />
          )}
          {type === DROPDOWN && (
            <FormComponent
              id={id}
              placeholder={label}
              selection
              options={options}
              onChange={handleChange}
              name={name}
              disabled={disabled}
              error={error}
              value={value}
            />
          )}
          {type === DROPDOWN_MULTIPLY && (
            <FormComponent
              placeholder={label}
              options={options}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              error={error}
            />
          )}
          {type === INPUT && (
            <div id={name}>
              <FormComponent
                id={String(id)}
                className={'small-input-field'}
                onChange={handleChange}
                name={name}
                value={value}
                disabled={disabled}
                icon={isInPercents && 'percent'}
                placeholder={label}
                error={error}
              />
            </div>
          )}
          {type === DATEPICKER && (
            <FormComponent
              id={id}
              onChange={value => handleChange(name, value)}
              name={name}
              disabled={disabled}
              position={'right-start'}
              showTimeSelect
              shouldCloseOnSelect
              selected={value}
              error={error}
            />
          )}
          {type === TOGGLE && (
            <div className="ipools-checkbox-wrapper">
              <FormComponent
                className="ipools-checkbox-toggle"
                id={id}
                toggle
                onChange={handleChange}
                name={name}
                disabled={disabled}
                checked={checked}
              />
              <Header className="ipools-checkbox-header" textAlign="center" as="h5">
                {label}
              </Header>
            </div>
          )}
          {type === ADD_BUTTON && (
            <FormComponent
              id={id}
              toggle
              onClick={handleChange}
              name={name}
              disabled={disabled}
              icon="add"
            />
          )}
          {type === TEXT_AREA && (
            <div id={name}>
              <FormComponent
                id={id}
                onChange={handleChange}
                name={name}
                value={value}
                disabled={disabled}
                placeholder={label}
                error={String(error)}
                style={
                  error
                    ? {
                        border: '1px solid #9f3a38',
                        borderRadius: '6px',
                        background: '#9f3a3814',
                      }
                    : null
                }
              />
            </div>
          )}
          {type === INPUT_WITH_DELETE && (
            <div id={name}>
              <FormComponent
                id={String(id)}
                className="small-input-field"
                onChange={handleChange}
                name={name}
                value={value}
                disabled={disabled}
                icon={isInPercents && 'percent'}
                placeholder={label}
                error={error}
              />
            </div>
          )}
        </Grid.Column>
        {type === INPUT_WITH_DELETE && (
          <Grid.Column
            // @ts-ignore
            width={deleteWidth}
          >
            <Button icon={'delete'} onClick={() => deleteInput(id)} disabled={disabled} />
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};
