import { PoolStatusEnum } from './constants';

type QuestionType =
  | 'QT_WIN_WIN'
  | 'QT_WIN_DRAW_WIN'
  | 'QT_MULTIPLE_CHOICE'
  | 'QT_NEAREST_TIEBREAK'
  | 'QT_NEAREST_WIN_OUTCOME'
  | 'QT_NEAREST_WIN_RANGE';

export type PoolQuestionType = {
  id: string;
  marketId: string;
  cutoffDate: string;
  questionType: QuestionType;
  answers: Array<PoolAnswerType>;
  descriptions: Array<{ lang: string; text: string }>;
  leagueDescriptions: Array<{ lang: string; text: string }>;
  infoDescriptions: Array<{ lang: string; text: string }>;
  feedMarketCutoffDate?: string;
};

export type PoolQuestionStateType = {
  id: string;
  marketId: string;
  cutoffDate: string;
  questionType: QuestionType;
  answers: Array<{
    id: string;
    outcomeId: string;
    scores: string | number;
    result: PoolAnswerResult;
    descriptions: Array<{ lang: string; text: string }>;
    valueFrom: number;
    valueTo: number;
    resultValue: number;
  }>;
  descriptions: Array<{ lang: string; text: string }>;
  leagueDescriptions: Array<{ lang: string; text: string }>;
  infoDescriptions: Array<{ lang: string; text: string }>;
  feedMarketCutoffDate?: string;
};

export type PoolAnswerResult = 'ANSWER_RESULT_UNKNOWN' | 'WIN' | 'LOSE' | 'NO_RESULT' | 'VOID' | 'VALUE';

export type PoolAnswerType = {
  id: string;
  outcomeId: string;
  scores: number[];
  result: PoolAnswerResult;
  descriptions: Array<{ lang: string; text: string }>;
  valueFrom: number;
  valueTo: number;
  resultValue: number;
};

export type PoolType = {
  id: string;
  description: string;
  originId: string;
  status: PoolStatusEnum;
  openDate: string;
  questions: Array<PoolQuestionType>;
  settleDate: string;
  lastUpdateDate: string;
  createDate: string;
  liveDate: string;
  origins: Array<{
    id: string;
    description: string;
  }>;
  createdBy: string;
  updatedBy: string;
  name: string;
};

type BasePoolType = {
  brand: string;
  originIds: Array<string>;
  descriptions: Array<DescriptionType>;
  names: Array<DescriptionType>;
  prizeJackpot: string;
  openDate: string;
  maxEntriesPerUser: string;
  maxEntriesPerPool: string;
  numberOfPlaces?: number;
  prizeDistribution: Array<string>;
  entryBet: string;
  commission: string;
  prizeBonusIndividual: string;
  insuranceCommission: string;
  prizeBonusTotal: string;
  operatorFundShortfall: boolean;
  isVisibleToUnregistered: boolean;
  isDoubleScore: boolean;
  prizeGuarantee: string;
  currencyCode?: string;
};

export type DescriptionType = {
  lang: string;
  text: string;
};

export type CreationFormDictType = BasePoolType & {
  brand?: string;
  numberOfPlaces?: number;
};

export type PoolInfoType = BasePoolType & {
  id: string;
  brandId: string;
  name: string;
  description: string;
  startDate: string;
  status: string;
  createDate: string;
  createdBy: string;
  settleDate?: string;
  settledBy: string;
  liveDate: string;
  questions: Array<PoolQuestionType>;
  prizeFundGuarantee: string;
  lastUpdateDate?: string;
  updatedBy?: string;
};

export type BrandsOrOriginsListRequestAnswer = {
  result: Array<{
    id: string;
    description: string;
    currencyCode?: string;
    currencySign?: string;
  }>;
};

export type DropdownElement = {
  key: string;
  text: string;
  value: string;
};

export type LanguagesListType = Array<{
  id: string;
  lang: string;
  name: string;
}>;

export type StatisticType = {
  prizeBonusTotal: string;
  prizeBonusIndividual: string;
  prizeBonusPaid: string;
  prizeBonusLeft: string;
  entriesTotal: string;
  takenPart: string;
  prizeJackpot: string;
  jackpotPaid: string;
  roundingTotal: string;
  commission: {
    total: string;
    value: string;
  };
  totalMoneyBet: string;
  entryBet: string;
  insuranceCommission: {
    total: string;
    value: string;
  };
  prizeFund: string;
  prizeFundGuarantee: string;
  prizeFundDistribution: Array<{
    total: string;
    value: string;
  }>;
  operatorFundShortfall: boolean;
};

export interface AnswerInEntries {
  questionId: string;
  questionDesc: string;
  answerId: string;
  answerDesc: string;
  score: string;
  isDouble: boolean;
}

export type EntriesType = {
  id: string;
  rank: string;
  origin: string;
  player: {
    playerId: string;
    playerName: string;
  };
  submitted: string;
  lastEdit: string;
  answers: Array<AnswerInEntries>;
  score: {
    currentScore: number;
    totalScore: number;
    tieBreakScore: number;
  };
  isJackpot: boolean;
  isBonus: boolean;
  totalAmount: string;
  isVoided: boolean;
  playerCurrencyCode: string;
  playerTotalAmount: number;
};

export type ReduxStateType = {
  variables: PoolVariablesType;
  poolInfo: PoolInfoType & { isPoolWithMultiplyChoice: boolean };
  poolStatistic: StatisticType;
  poolEntries: ReduxEntriesType;
  feedQuestionSearch: FeedQuestionsSearchState;
  poolTable: IpoolsTableType;
};

export type IpoolsTableType = {
  queryStatus: PoolStatusEnum & 'ALL';
  poolList: Array<PoolType>;
  queryOriginId?: string;
  querySearch?: string;
};

export type PoolVariablesType = {
  actualId: number | string;
  actualLanguage: string;
  originId?: string;
};

export type ReduxEntriesType = {
  entries: Array<EntriesType>;
  activeRows: Array<string>;
};

export type UserType = {
  username: string;
  originId: string;
  jwt: string;
  permissions: UserPermissions;
};

export type UserPermissions = {
  allowPermissions: Array<string>;
  denyPermissions: Array<string>;
  roles: Array<string>;
};

export type FeedQuestionsSearchState = {
  limit: number;
  startDateFrom?: string | Date;
  searchLine?: string;
  startDateTo?: string | Date;
  sportId?: string;
  regionId?: string;
  leagueId?: string;
};

export type FeedQuestionMarketType = {
  id: string;
  description: string;
  outcomes: Array<{
    id: string;
    description: string;
    priceId: number;
    priceValue: number;
  }>;
};

export type FeedQuestionType = {
  description: string;
  feedCode: string;
  id: string;
  leagueDescription: string;
  leagueId: string;
  markets: Array<FeedQuestionMarketType>;
  regionDescription: string;
  regionId: string;
  sportDescription: string;
  sportId: string;
  startDate: string;
};

export enum IpoolsColumnLabelEnum {
  Id = 'Id',
  Name = 'Name',
  Status = 'Status',
  Origins = 'Origins',
  BettingStartTime = 'Betting Start Time',
  BettingEndTime = 'Betting End Time',
  SettlementTime = 'Settlement Time',
  Created = 'Created',
  LastUpdated = 'Last Updated',
  Empty = '',
}

export enum IpoolsTableSortByEnum {
  Id = 'id',
}

export type IpoolsTableSortingState = {
  column: IpoolsTableSortByEnum.Id | null | void;
  direction: 'ascending' | 'descending';
};
