import { StatisticType } from '../../types';

const initialState: StatisticType = {
  prizeBonusTotal: null,
  prizeBonusIndividual: null,
  prizeBonusPaid: null,
  prizeBonusLeft: null,
  entriesTotal: null,
  takenPart: null,
  prizeJackpot: null,
  jackpotPaid: null,
  roundingTotal: null,
  commission: {
    total: null,
    value: null,
  },
  totalMoneyBet: null,
  entryBet: null,
  insuranceCommission: {
    total: null,
    value: null,
  },
  prizeFund: null,
  prizeFundGuarantee: null,
  prizeFundDistribution: [
    {
      total: null,
      value: null,
    }
  ],
  operatorFundShortfall: null,
};

const CHANGE_POOL_STATISTIC = 'CHANGE_POOL_STATISTIC';
const DEFAULT_POOL_STATISTIC = 'DEFAULT_POOL_STATISTIC';

export const poolStatisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_POOL_STATISTIC:
      return {
        ...state,
        prizeBonusTotal: action.payload.prizeBonusTotal,
        prizeBonusIndividual: action.payload.prizeBonusIndividual,
        prizeBonusPaid: action.payload.prizeBonusPaid,
        prizeBonusLeft: action.payload.prizeBonusLeft,
        entriesTotal: action.payload.entriesTotal,
        takenPart: action.payload.takenPart,
        prizeJackpot: action.payload.prizeJackpot,
        jackpotPaid: action.payload.jackpotPaid,
        roundingTotal: action.payload.roundingTotal,
        commission: action.payload.commission,
        totalMoneyBet: action.payload.totalMoneyBet,
        entryBet: action.payload.entryBet,
        insuranceCommission: action.payload.insuranceCommission,
        prizeFund: action.payload.prizeFund,
        prizeFundGuarantee: action.payload.prizeFundGuarantee,
        prizeFundDistribution: action.payload.prizeFundDistribution,
        operatorFundShortfall: action.payload.operatorFundShortfall,
      };
    case DEFAULT_POOL_STATISTIC:
      return { initialState };
    default:
      return state;
  }
};

export const changePoolStatisticAction = payload => ({
  type: CHANGE_POOL_STATISTIC,
  payload,
});
export const removePoolStatisticAction = () => ({
  type: DEFAULT_POOL_STATISTIC,
});
