import React, { ReactNode } from 'react';

import { ToastContainer } from 'react-toastify';

import { HeaderMenu } from './HeaderMenu';

export const Layout = React.memo(
  ({ username, logout, children }: { username: string; logout: () => void; children: ReactNode }) => (
    <>
      <HeaderMenu username={username} logout={logout} />
      <div style={{ padding: '40px 0 0' }}>{children}</div>
      <ToastContainer />
    </>
  )
);
