import React from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Grid } from 'semantic-ui-react';

import { PoolInfoSegment } from './PoolInfoSegment';
import { PoolStatisticSegment } from './PoolStatisticSegment';
import { Loading } from '../../components/AxiosRequest';
import { LanguageSwitcher } from '../../components/LanguageSwitcher';
import { MenuPoolPage } from '../../components/MenuPoolPage';
import { API_ENTRIES, API_FIND_BY_ID, API_POOL_STATISTICS } from '../../constants';
import { changePoolEntriesAction } from '../../store/reducers/poolEntries';
import { changePoolInfoAction } from '../../store/reducers/poolInfo';
import { changePoolStatisticAction } from '../../store/reducers/poolStatistic';
import { changeIdAction, changeLanguageAction } from '../../store/reducers/poolVariables';
import { ReduxStateType } from '../../types';
import { updatePoolInfo, updatePoolStatistic } from '../../utils';
import './PoolPage.css';

export const PoolPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { poolId } = params;

  const [ isFetch, setIsFetch ] = React.useState(false);

  const fetchInfoOfPool = React.useCallback(() => {
    axios
      .all([
        axios.get(API_FIND_BY_ID, { params: { id: poolId }}),
        axios.get(API_POOL_STATISTICS, {
          params: { poolId: poolId },
        }),
        axios.get(API_ENTRIES, { params: { poolId: poolId }})
      ])
      .then(
        axios.spread((obj1, obj2, obj3) => {
          dispatch(changeLanguageAction(obj1.data.result.names[0].lang));
          dispatch(changePoolInfoAction(obj1.data.result));
          dispatch(changePoolStatisticAction(obj2.data.result));
          dispatch(changePoolEntriesAction(obj3.data.result));
        })
      )
      .finally(() => setIsFetch(true))
      .catch(error => toast.error(error));
  }, [ dispatch, poolId ]);

  React.useEffect(() => {
    dispatch(changeIdAction(poolId));
    fetchInfoOfPool();

    const intervalInfo = setInterval(() => {
      updatePoolInfo({ poolId, dispatch });
    }, 5000);
    const intervalStatistic = setInterval(() => {
      updatePoolStatistic({ poolId, dispatch });
    }, 10000);

    return () => {
      clearInterval(intervalInfo);
      clearInterval(intervalStatistic);
    };
    //eslint-disable-next-line
  }, []);

  if (!isFetch) return <Loading />;

  return <PoolPageComponent />;
};

const PoolPageComponent = () => {
  const state = useSelector((state: ReduxStateType) => state);
  const { id } = state.poolInfo;

  return (
    <div className="pool-page">
      <div className="pool-page__header">
        <Button icon="arrow left" as={Link} to="/ipools" />
        <LanguageSwitcher />
      </div>
      <div className="pool-page__id">
        <span>ID:{id}</span>
      </div>
      <div className="common-info-grid">
        <Grid>
          <Grid.Column width={9}>
            <PoolInfoSegment />
          </Grid.Column>
          <Grid.Column width={7}>
            <PoolStatisticSegment />
          </Grid.Column>
        </Grid>
      </div>
      <MenuPoolPage />
      <Outlet />
    </div>
  );
};
