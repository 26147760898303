import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Header, Table } from 'semantic-ui-react';

import { EntriesBonusSelector } from './EntriesBonusSelector';
import { EntriesTableRow } from './EntriesTableRow';
import { ReduxStateType } from '../../../types';
import { updatePoolEntries } from '../../../utils';

import './EntriesTab.css';

export const EntriesTab = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { poolId } = params;

  React.useEffect(() => {
    const intervalEntries = setInterval(() => {
      updatePoolEntries({ poolId, dispatch });
    }, 5000);
    return () => {
      clearInterval(intervalEntries);
    };
    // eslint-disable-next-line
  }, []);

  const { entries: entriesList } = useSelector((state: ReduxStateType) => state.poolEntries);

  const [ sortingState, setSortingState ] = React.useState<{
    column: 'rank' | null | void;
    direction: 'ascending' | 'descending' | null | void;
  }>({
    column: null,
    direction: null,
  });

  const sortBy = React.useCallback(
    column => {
      setSortingState(state => ({
        column,
        direction: state.direction === 'ascending' ? 'descending' : 'ascending',
      }));
    },
    [ setSortingState ]
  );

  const sortByRank = React.useCallback(() => sortBy('rank'), [ sortBy ]);

  const sortedEntriesList = React.useMemo(() => {
    if (sortingState.column === 'rank') {
      const sortingEntriesList = entriesList;
      sortingEntriesList.sort((a, b) =>
        sortingState.direction === 'ascending'
          ? Number(b.rank) - Number(a.rank)
          : Number(a.rank) - Number(b.rank)
      );
      return sortingEntriesList;
    } else return entriesList;
  }, [ entriesList, sortingState ]);

  if (entriesList.length !== 0)
    return (
      <>
        <EntriesBonusSelector />
        <article className="pool-page-entries-table-wrapper">
          <Table sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  //@ts-ignore
                  sorted={sortingState.column === 'rank' ? sortingState.direction : null}
                  onClick={sortByRank}
                >
                  {'Rank'}
                </Table.HeaderCell>
                <Table.HeaderCell>{'Origin'}</Table.HeaderCell>
                <Table.HeaderCell>{'Player'}</Table.HeaderCell>
                <Table.HeaderCell>{'Currency'}</Table.HeaderCell>
                <Table.HeaderCell>{'Submitted'}</Table.HeaderCell>
                <Table.HeaderCell>{'Last edit'}</Table.HeaderCell>
                <Table.HeaderCell>{'Answer and scores'}</Table.HeaderCell>
                <Table.HeaderCell>{'Total Score'}</Table.HeaderCell>
                <Table.HeaderCell>{'Prize amount'}</Table.HeaderCell>
                <Table.HeaderCell>{'Jackpot'}</Table.HeaderCell>
                <Table.HeaderCell>{'Bonus'}</Table.HeaderCell>
                <Table.HeaderCell>{'Voided'}</Table.HeaderCell>
                <Table.HeaderCell>{''}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sortedEntriesList.map(entre => (
                <EntriesTableRow key={entre.id} entre={entre} />
              ))}
            </Table.Body>
          </Table>
        </article>
      </>
    );

  return (
    <Header as="h1" textAlign="center" className="pool-pane__header">
      No entries now
    </Header>
  );
};
