const initialState = {
  queryStatus: null,
  queryOriginId: null,
  querySearch: null,
  poolList: [],
};

const CHANGE_QUERY__STATUS = 'CHANGE_QUERY__STATUS';
const CHANGE_QUERY_ORIGIN_ID = 'CHANGE_QUERY__ORIGIN_ID';
const CHANGE_QUERY_SEARCH = 'CHANGE_QUERY_SEARCH';
const CHANGE_POOL_LIST = 'CHANGE_POOL_LIST';

export const poolTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_QUERY__STATUS:
      return { ...state, queryStatus: action.payload };
    case CHANGE_QUERY_ORIGIN_ID:
      return { ...state, queryOriginId: action.payload };
    case CHANGE_QUERY_SEARCH:
      return { ...state, querySearch: action.payload };
    case CHANGE_POOL_LIST:
      return { ...state, poolList: action.payload };
    default:
      return state;
  }
};

export const changeStatusAction = payload => ({
  type: CHANGE_QUERY__STATUS,
  payload,
});

export const changeOriginIdAction = payload => ({
  type: CHANGE_QUERY_ORIGIN_ID,
  payload,
});

export const changeSearchAction = payload => ({
  type: CHANGE_QUERY_SEARCH,
  payload,
});

export const changePoolListAction = payload => ({
  type: CHANGE_POOL_LIST,
  payload,
});
