import React from 'react';
import './NoDataComponent.scss';

export const NoDataComponent = () => (
  <tr>
    <td colSpan={9} className="no-data-component">
      NO POOLS
    </td>
  </tr>
);
