import React from 'react';

import { AxiosRequestConfig } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import { DropdownWithFetch } from '../../components/table-filters-components/DropdownWithFetch';
import { GoToPoolComponent } from '../../components/table-filters-components/GoToPoolComponent';
import { SearchPoolTableComponent } from '../../components/table-filters-components/SearchPoolTableComponent';
import { API_ORIGINS_FIND, OFFSIDE_GAMING_ID, POOL_STATUS_ALL, PoolStatusEnum } from '../../constants';
import { changeOriginIdAction, changeStatusAction } from '../../store/reducers/poolTable';
import { ReduxStateType } from '../../types';
import { getOptionText } from '../../utils';

const OPTIONS = [
  POOL_STATUS_ALL,
  PoolStatusEnum.DRAFT,
  PoolStatusEnum.AVAILABLE,
  PoolStatusEnum.OPEN,
  PoolStatusEnum.LIVE,
  PoolStatusEnum.COMPLETED,
  PoolStatusEnum.SETTLED,
  PoolStatusEnum.SETTLED_WITH_ERRORS,
  PoolStatusEnum.SETTLE_IN_PROGRESS,
  PoolStatusEnum.VOIDED
];

const DEFAULT_ORIGIN_OPTIONS = [
  {
    key: 'ALL',
    value: 'ALL',
    text: 'All origins',
  }
];

export const IpoolsTableMenu = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);
  const { queryStatus: status, queryOriginId: originId } = state.poolTable;

  const dropdownOptions = OPTIONS.map(status => ({
    key: status,
    value: status,
    text: getOptionText(status),
  }));

  const originsConfig: AxiosRequestConfig = {
    method: 'GET',
    url: API_ORIGINS_FIND,
    params: { brandId: OFFSIDE_GAMING_ID },
  };

  function dropdownOnChange(e, { value }) {
    dispatch(changeStatusAction(value));
  }

  function originsFilterOnChange(e, { value }) {
    dispatch(changeOriginIdAction(value));
  }

  return (
    <div className="ipools-table__menu">
      <div>
        <Dropdown
          selection
          options={dropdownOptions}
          placeholder="Status"
          value={status}
          onChange={dropdownOnChange}
        />
        <DropdownWithFetch
          config={originsConfig}
          onChange={originsFilterOnChange}
          placeholder="Origin ID"
          value={originId}
          defaultOptions={DEFAULT_ORIGIN_OPTIONS}
        />
        <GoToPoolComponent />
      </div>

      <SearchPoolTableComponent />
    </div>
  );
};
