import React, { FC } from 'react';

import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Icon, Table } from 'semantic-ui-react';

import { IPOOLS_DATE_FORMAT } from '../../../constants';
import { addEntreRowAction, removeEntreRowAction } from '../../../store/reducers/poolEntries';
import { AnswerInEntries, EntriesType, ReduxStateType } from '../../../types';
import { formattingDate } from '../../../utils';
import './EntriesTableRow.css';

export const EntriesTableRow = ({ entre }: { entre: EntriesType }) => {
  const dispatch = useDispatch();
  const { currencyCode } = useSelector((state: ReduxStateType) => state.poolInfo);
  const { activeRows } = useSelector((state: ReduxStateType) => state.poolEntries);

  const [ isShort, setIsShort ] = React.useState(true);

  const submittedDate = formattingDate(entre.submitted, IPOOLS_DATE_FORMAT).split(' ');

  const lastEditDate = formattingDate(entre.lastEdit && entre.submitted, IPOOLS_DATE_FORMAT).split(' ');
  const scoreTiebreak = entre.score.tieBreakScore ? `(${entre.score.tieBreakScore})` : '';

  const playersCurrencyTotalAmount =
    entre.playerCurrencyCode !== currencyCode && !!entre.playerTotalAmount
      ? `(${entre.playerCurrencyCode} ${entre.playerTotalAmount})`
      : '';

  const isActive = activeRows.includes(entre.id);

  const changeRow = React.useCallback(() => {
    if (!isActive) {
      dispatch(addEntreRowAction(entre.id));
    } else {
      dispatch(removeEntreRowAction(entre.id));
    }
  }, [ dispatch, entre.id, isActive ]);

  return (
    <Table.Row className={cx('entries-table-row', { 'entries-table-row_active': isActive })}>
      <Table.Cell>{entre.rank || '-'}</Table.Cell>
      <Table.Cell>{entre.origin || '-'}</Table.Cell>
      <Table.Cell>
        {entre.player.playerId || '-'}
        <br />
        {entre.player.playerName || '-'}
      </Table.Cell>
      <Table.Cell>{entre.playerCurrencyCode || '-'}</Table.Cell>
      <Table.Cell>
        {submittedDate[0]}
        <br />
        {submittedDate[1]}
      </Table.Cell>
      <Table.Cell>
        {lastEditDate[0]}
        <br />
        {lastEditDate[1]}
      </Table.Cell>
      <Table.Cell className={'entries-desc-cell-answers-wrapper'}>
        <div
          className={cx('entries-desc-cell-answers', {
            'entries-desc-cell-answers_short': isShort,
            'entries-desc-cell-answers_long': !isShort,
          })}
        >
          {entre.answers.map(answer =>
            isShort ? (
              <ShortAnswer key={answer.questionId + answer.answerId} {...answer} />
            ) : (
              <LongAnswer key={answer.questionId + answer.answerId} {...answer} />
            )
          )}
        </div>
        <div
          className={cx('button-up-down', { 'button-up-down_active': !isShort })}
          onClick={() => setIsShort(!isShort)}
        >
          <Icon name="caret down" />
        </div>
      </Table.Cell>
      <Table.Cell>
        {entre.score.currentScore || '-'}/{entre.score.totalScore || '-'}
        <br />
        {scoreTiebreak}
      </Table.Cell>
      <Table.Cell>
        {entre.totalAmount ?? '-'} <br />
        {playersCurrencyTotalAmount}
      </Table.Cell>
      <Table.Cell className={'entries-desc-cell-jackpot'}>{entre.isJackpot && 'Jackpot'}</Table.Cell>
      <Table.Cell className={'entries-desc-cell-bonus'}>{entre.isBonus && 'Bonus'}</Table.Cell>
      <Table.Cell className={'entries-desc-cell-void'}>{entre.isVoided && 'Voided'}</Table.Cell>
      <Table.Cell className={'entries-desc-cell-checkbox'}>
        <Checkbox onClick={() => changeRow()} checked={isActive} />
      </Table.Cell>
    </Table.Row>
  );
};

const ShortAnswer: FC<AnswerInEntries> = React.memo(({ answerDesc, score, isDouble }) => (
  <div className={'entries-desc-short-answer'}>
    <span className={cx('entries-desc-title', { 'entries-desc-title_active': isDouble })}>
      {answerDesc}
    </span>
    <span className={cx('entries-desc-score', { 'entries-desc-score_active': isDouble })}>{score}</span>
  </div>
));

const LongAnswer: FC<AnswerInEntries> = React.memo(({ questionDesc, answerDesc, score, isDouble }) => (
  <div className={'entries-desc-long-answer'}>
    <span className={'entries-desc-title'}>({answerDesc}</span>
    <span className={'entries-desc-score'}>{score}</span>
    <span className={cx({ 'entries-desc-double': isDouble })}>{isDouble && 'Double'}</span>
    <span>)</span>
    <span className={'entries-desc-question'}>{questionDesc}</span>
  </div>
));
