import React from 'react';

import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';

import { PoolQuestionCard } from './QuestionCard/PoolQuestionCard';
import { QuestionsButtonPanel } from './QuestionsButtonPanel';
import { ReduxStateType } from '../../../types';
import './PoolQuestionsTab.scss';

export const PoolQuestionsTab = () => {
  const state = useSelector((state: ReduxStateType) => state);
  const { questions } = state.poolInfo;
  const sortedQuestions = questions.sort(
    (a, b) => Number(new Date(a.cutoffDate)) - Number(new Date(b.cutoffDate))
  );

  return (
    <div className={cx('question-tab', !questions.length && 'question-tab_empty')}>
      {!questions.length && (
        <Header as="h1" textAlign="center" className="question-tab__header">
          No questions added yet.
          <br />
          Add first.
        </Header>
      )}

      <QuestionsButtonPanel />

      {questions?.length > 0 &&
        sortedQuestions?.map(question => <PoolQuestionCard key={question.id} question={question} />)}
    </div>
  );
};
