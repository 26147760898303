import React from 'react';

import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Input } from 'semantic-ui-react';

import { useAxiosRequest } from '../../../../../components/use-axios-request/use-axios-request.es';
import {
  API_LEAGUES_FIND,
  API_REGIONS_FIND,
  API_SPORTS_FIND,
  FEED_SEARCH_TIME_TYPE
} from '../../../../../constants';
import {
  changeDateFromSearchAction,
  changeDateToSearchAction,
  changeLeagueSearchAction,
  changeLineSearchAction,
  changeRegionSearchAction,
  changeSportSearchAction
} from '../../../../../store/reducers/searchStateFeedQuestions';
import { ReduxStateType } from '../../../../../types';

export const FeedQuestionSearchInput = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { searchLine } = state.feedQuestionSearch;

  function onChange(e, g) {
    dispatch(changeLineSearchAction(g.value));
  }

  return (
    <Input value={searchLine || ''} onChange={onChange} icon="search" placeholder="Search..." fluid />
  );
};

export const FeedQuestionsStartAndEndDate = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { startDateFrom, startDateTo } = state.feedQuestionSearch;

  function startDateOnChange(date) {
    dispatch(changeDateFromSearchAction(date));
  }

  function endDateOnChange(date) {
    dispatch(changeDateToSearchAction(date));
  }

  return (
    <div style={{ margin: '10px 0px' }}>
      <DatePicker
        placeholderText="Start date"
        selected={startDateFrom}
        onChange={startDateOnChange}
        position="right-start"
        showTimeSelect
        shouldCloseOnSelect
        isClearable
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat={FEED_SEARCH_TIME_TYPE}
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
      />
      <DatePicker
        placeholderText="End date"
        selected={startDateTo || null}
        onChange={endDateOnChange}
        minDate={startDateFrom || null}
        position="right-start"
        showTimeSelect
        shouldCloseOnSelect
        isClearable
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat={FEED_SEARCH_TIME_TYPE}
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
      />
    </div>
  );
};

export const SportsDropdownWithAxios = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { sportId } = state.feedQuestionSearch;

  const { data, isFetching } = useAxiosRequest(API_SPORTS_FIND);

  const options =
    data?.result?.map(({ id, description }) => ({
      value: id,
      text: description || '',
    })) || [];

  function onChange(e, { value }) {
    dispatch(changeSportSearchAction(value));
  }

  return (
    <Dropdown
      options={options}
      loading={isFetching}
      placeholder="Sports"
      search
      lazyLoad
      selection
      clearable
      closeOnChange
      fluid
      value={sportId || null}
      onChange={onChange}
    />
  );
};

export const RegionDropdownWithAxios = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { sportId, regionId } = state.feedQuestionSearch;

  const config = React.useMemo(() => {
    if (sportId) {
      return {
        url: API_REGIONS_FIND,
        params: {
          sportIds: sportId,
        },
      };
    }
  }, [ sportId ]);

  const { data, isFetching } = useAxiosRequest(config);

  const options =
    (sportId &&
      data?.result?.map(({ id, description }) => ({
        value: id,
        text: description || '',
      }))) ||
    [];

  const onChange = React.useCallback(
    (e, { value }) => {
      dispatch(changeRegionSearchAction(value));
    },
    [ dispatch ]
  );

  const disabled = !sportId;

  return (
    <Dropdown
      value={regionId || null}
      options={options}
      loading={isFetching}
      placeholder="Regions"
      onChange={onChange}
      disabled={disabled}
      search
      lazyLoad
      selection
      clearable
      closeOnChange
      fluid
    />
  );
};

export const LeagueDropdownWithAxios = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { sportId, regionId, leagueId } = state.feedQuestionSearch;

  const config = React.useMemo(() => {
    if (regionId) {
      return {
        url: API_LEAGUES_FIND,
        params: {
          regionIds: regionId,
        },
      };
    }
  }, [ regionId ]);

  const { data, isFetching } = useAxiosRequest(config);

  const options =
    (regionId &&
      data?.result?.map(({ id, description }) => ({
        value: id,
        text: description || '',
      }))) ||
    [];

  const disabled = !sportId || !regionId;

  function onChange(e, { value }) {
    dispatch(changeLeagueSearchAction(value));
  }

  return (
    <Dropdown
      placeholder="Leagues"
      value={leagueId || null}
      options={options}
      loading={isFetching}
      onChange={onChange}
      disabled={disabled}
      search
      lazyLoad
      selection
      clearable
      closeOnChange
      fluid
    />
  );
};
