import React from 'react';

import classNames from 'classnames';
import { Editor, EditorState, KeyBindingUtil, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import { Toolbar } from './Toolbar';
import { getOptions, htmlToDraftBlocks, replaceValue } from './utils';
import { CreationFormDictType } from '../../types';
import './TextEditor.css';
import 'draft-js/dist/Draft.css';

export const TextEditor = ({
  value,
  name,
  error,
  formState,
  setFormState,
  setBackToPool,
}: {
  value: string;
  name: string;
  error?: boolean;
  formState: CreationFormDictType;
  setFormState: (CreationFormDictType) => void;
  setBackToPool: (boolean) => void;
}) => {
  const initialState = htmlToDraftBlocks(value);

  const [ editorState, setEditorState ] = React.useState(initialState);

  React.useEffect(() => {
    setEditorState(htmlToDraftBlocks(value));
  }, [ value ]);

  const changeFormState = (state: EditorState) => {
    const [ fieldName, lang ] = name.split('_');
    const langFieldValues = formState[fieldName];
    const value = stateToHTML(state.getCurrentContent(), getOptions());

    const updatedLangFieldValues = langFieldValues.map(langDict => {
      if (langDict.lang === lang)
        return {
          lang: lang,
          text: replaceValue(value),
        };
      return langDict;
    });

    setFormState({
      ...formState,
      [fieldName]: updatedLangFieldValues,
    });

    setBackToPool(false);
  };

  const onInlineClick = (e: string) => {
    const nextState = RichUtils.toggleInlineStyle(editorState, e);
    setEditorState(nextState);
  };

  const onBlockClick = (e: string) => {
    const nextState = RichUtils.toggleBlockType(editorState, e);
    setEditorState(nextState);
  };

  const onChange = (state: EditorState) => {
    setEditorState(state);
    setBackToPool(false);
  };

  const onBlur = () => {
    changeFormState(editorState);
  };

  const handleReturn = event => {
    if (KeyBindingUtil.isSoftNewlineEvent(event)) {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }
    return 'not-handled';
  };

  return (
    <div
      className={classNames('text-editor-wrapper', {
        'text-editor-wrapper_error': error,
      })}
      onBlur={onBlur}
    >
      <div className={'text-editor__toolbar'}>
        <Toolbar editorState={editorState} onInlineClick={onInlineClick} onBlockClick={onBlockClick} />
      </div>
      <div className={'text-editor__textarea'}>
        <Editor
          placeholder={'Description'}
          editorState={editorState}
          onChange={onChange}
          handleReturn={handleReturn}
        />
      </div>
    </div>
  );
};
