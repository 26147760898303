import React from 'react';

import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Icon, Input } from 'semantic-ui-react';

import { FilterDatePicker } from '../../../../components/FilterDatePicker';
import { useAxiosRequest } from '../../../../components/use-axios-request/use-axios-request.es';
import {
  API_QUESTION_REMOVE,
  CUTOFF_DATE,
  MANUAL_MARKET_ID,
  QUESTION_HEADER_DESCRIPTION,
  QUESTION_INFO_DESCRIPTION,
  QUESTION_MULTIPLY,
  QUESTION_NEAREST_WIN_OUTCOME,
  QUESTION_NEAREST_WIN_RANGE,
  QUESTION_TIEBREAK,
  QUESTION_WIN_DRAW_WIN,
  QUESTION_WIN_WIN
} from '../../../../constants';
import { PoolQuestionStateType, ReduxStateType } from '../../../../types';
import { switchDescription, updatePoolInfo } from '../../../../utils';

export const QuestionHeaderRow = ({
  questionState,
  isEditable,
  setQuestionState,
  isLive,
  questionInputOnChange,
  isEnded,
  errorsState,
  isRange,
}: {
  questionState: PoolQuestionStateType;
  isEditable: boolean;
  setQuestionState: (questionState: PoolQuestionStateType) => void;
  isLive: boolean;
  questionInputOnChange: (data, value, arrOfDescriptions) => void;
  isEnded: boolean;
  errorsState: Array<string>;
  isRange: boolean;
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { actualLanguage, actualId: poolId } = state.variables;
  const { isPoolWithMultiplyChoice } = state.poolInfo;

  const actualDescriptionValue = switchDescription(questionState.descriptions, actualLanguage);
  const actualInfoDescriptionValue = switchDescription(questionState.infoDescriptions, actualLanguage);

  const cutoffDate = new Date(questionState.cutoffDate);

  const questionTypeDescription = React.useMemo(() => {
    switch (questionState.questionType) {
      case QUESTION_WIN_WIN: {
        return 'Win/Win';
      }
      case QUESTION_WIN_DRAW_WIN: {
        return 'Win/Draw/Win';
      }
      case QUESTION_MULTIPLY: {
        return 'Multiply';
      }
      case QUESTION_TIEBREAK: {
        return 'Tiebreak';
      }
      case QUESTION_NEAREST_WIN_OUTCOME: {
        return 'Nearest win outcome';
      }
      case QUESTION_NEAREST_WIN_RANGE: {
        return 'Nearest win range';
      }
      default: {
        return '';
      }
    }
  }, [ questionState.questionType ]);

  const dateHandleChange = React.useCallback(
    (data: Date) => {
      const formatDate = data.toISOString().replace(/\.\S{0,3}Z/gm, 'Z');

      setQuestionState({
        ...questionState,
        cutoffDate: formatDate,
      });
    },
    [ questionState, setQuestionState ]
  );

  const { update } = useAxiosRequest(null, {
    onSuccess: () => {
      updatePoolInfo({ poolId, dispatch });
      toast.success('Question was successfully removed');
    },
    onError: data => toast.error(data.message),
  });

  const removeQuestion = () => {
    update({
      method: 'POST',
      url: API_QUESTION_REMOVE,
      data: {
        id: questionState.id,
      },
    });
  };

  const descriptionOnChange = React.useCallback(
    (e, data) => {
      questionInputOnChange(e, data, questionState.descriptions);
    },
    [ questionInputOnChange, questionState.descriptions ]
  );

  const infoDescriptionOnChange = React.useCallback(
    (e, data) => {
      questionInputOnChange(e, data, questionState.infoDescriptions);
    },
    [ questionInputOnChange, questionState.infoDescriptions ]
  );

  return (
    <div className="question-header-row-wrapper">
      <div className="question-header-row">
        <Input
          className={cx('question-header-row__description', {
            'question-header-row__description_long': isPoolWithMultiplyChoice,
          })}
          size="small"
          value={actualDescriptionValue}
          disabled={!isEditable}
          id={QUESTION_HEADER_DESCRIPTION}
          onChange={descriptionOnChange}
          error={errorsState.includes(QUESTION_HEADER_DESCRIPTION)}
        />

        <div className="question-header-row__type">{questionTypeDescription}</div>

        {!isPoolWithMultiplyChoice && questionState.marketId === MANUAL_MARKET_ID && (
          <div className="question-header-row__manual">Manual</div>
        )}
        <div
          className={cx('question-header-row__time-section', {
            'question-header-row__time-section_end': isEnded,
            'question-header-row__time-section_live': isLive && !isEnded,
          })}
        >
          <div className="question-header-row__time-label">
            {isEnded && 'Ended'}
            {isLive && !isEnded && 'Live'}
          </div>
          <FilterDatePicker
            name="cutoffDate"
            selected={cutoffDate}
            showTimeSelect
            disabled={!isEditable}
            onChange={dateHandleChange}
            isClearable={false}
            error={errorsState.includes(CUTOFF_DATE)}
          />
          <Button
            className="question-header-row__btn-delete"
            onClick={removeQuestion}
            disabled={!isEditable}
          >
            <Icon name="close" size="large" />
          </Button>
        </div>
      </div>
      {isPoolWithMultiplyChoice && (
        <div className="question-header-row__info-description-wrapper">
          <Input
            className="question-header-row__info-description"
            size="small"
            value={actualInfoDescriptionValue}
            disabled={!isEditable}
            id={QUESTION_INFO_DESCRIPTION}
            onChange={infoDescriptionOnChange}
            error={errorsState.includes(QUESTION_INFO_DESCRIPTION)}
            placeholder="Info (optional)"
          />
        </div>
      )}
    </div>
  );
};
