import React from 'react';

import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Header, Icon } from 'semantic-ui-react';

import { CreationFormField } from './CreationFormField';
import { getInitialState, originsOrBrandsOnSuccess, validateField } from './helpers';
import { LanguageTabs } from './LanguageTabs';
import {
  API_BRAND_LIST,
  API_ORIGINS_FIND,
  API_POOL_CREATE,
  API_POOL_UPDATE,
  BRAND_FIELD,
  COMMISSION_FIELD,
  DATEPICKER,
  DESCRIPTIONS_FIELD,
  DOUBLE_SCORE_FIELD,
  DROPDOWN,
  ENTRY_BET_FIELD,
  INPUT,
  INSURANCE_COMMISSION_FIELD,
  MAX_ENTRIES_PER_POOL_FIELD,
  MAX_ENTRIES_PER_USER_FIELD,
  NAMES_FIELD,
  NUMBER_OF_PLACES,
  OPEN_DATE_FIELD,
  OPERATOR_FUND_SHORTFALL_FIELD,
  ORIGIN_CURRENCY,
  ORIGINS_FIELD,
  POOL_ID,
  POOL_STATUS_ENUM,
  PRIZE_BONUS_INDIVIDUAL_FIELD,
  PRIZE_BONUS_TOTAL_FIELD,
  PRIZE_CASH_DISTRIBUTION_FIELD,
  PRIZE_DISTRIBUTION_FIELD,
  PRIZE_GUARANTEE_FIELD,
  PRIZE_JACKPOT_FIELD,
  TOGGLE,
  VISIBLE_TO_UNREGISTER_FIELD
} from '../../constants';
import { BrandsOrOriginsListRequestAnswer, LanguagesListType, ReduxStateType } from '../../types';
import { updatePoolInfo } from '../../utils';
import { PrizePlacesComponent } from '../PrizePlacesComponent';
import { useAxiosRequest } from '../use-axios-request/use-axios-request.es';
import './EditForm.scss';

export const EditForm = ({
  isCreate,
  langsList,
}: {
  isCreate: boolean;
  langsList: LanguagesListType;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state: ReduxStateType) => state);
  const { id, status } = state.poolInfo;

  const initialState = React.useMemo(() => {
    return getInitialState(langsList, state.poolInfo);
  }, [ langsList, state.poolInfo ]);

  const [ formState, setFormState ] = React.useState({ ...initialState });
  const [ brandsState, setBrandsState ] = React.useState([]);
  const [ originsState, setOriginsState ] = React.useState([]);
  const [ errorFieldsState, setErrorFieldsState ] = React.useState([]);
  const [ backToPool, setBackToPool ] = React.useState(false);
  const [ isCash, setIsCash ] = React.useState(false);

  const isChanges = !isEqual(initialState, formState);
  const disableNotDraftStatus =
    [ POOL_STATUS_ENUM.AVAILABLE, POOL_STATUS_ENUM.OPEN, POOL_STATUS_ENUM.LIVE ]
      .map(s => String(s))
      .includes(status) && !isCreate;

  const onSubmitSuccess = React.useCallback(
    data => {
      if (!isCreate) {
        setBackToPool(true);
        updatePoolInfo({ poolId: id, dispatch });
      } else {
        navigate(`/ipools/${data.result.id}`);
      }
    },
    [ dispatch, id, isCreate, navigate ]
  );

  const { update: submitUpdate, isFetching: updateIsFetching } = useAxiosRequest(null, {
    onSuccess: onSubmitSuccess,
    onError: error => toast.error(error.message),
  });

  const handleSubmit = React.useCallback(() => {
    const dataForRequest = { ...formState };
    if (isCreate) delete dataForRequest[POOL_ID];

    delete dataForRequest[ORIGIN_CURRENCY];
    delete dataForRequest[NUMBER_OF_PLACES];
    delete dataForRequest[PRIZE_CASH_DISTRIBUTION_FIELD];

    const config = {
      method: isCreate ? 'POST' : 'PUT',
      url: isCreate ? API_POOL_CREATE : API_POOL_UPDATE,
      data: dataForRequest,
    };

    const errorFields = [];

    for (const fieldName in dataForRequest) {
      if (fieldName !== NAMES_FIELD && fieldName !== DESCRIPTIONS_FIELD) {
        const errorField = validateField(
          isCreate,
          formState,
          fieldName,
          dataForRequest[fieldName],
          dataForRequest,
          status
        );
        errorField && errorFields.push(errorField);
        continue;
      }

      const isEngNameEmpty = !dataForRequest[NAMES_FIELD][0].text;
      const isEngDescriptionEmpty = !dataForRequest[DESCRIPTIONS_FIELD][0].text;

      if (isEngNameEmpty && !errorFields.includes(NAMES_FIELD)) {
        toast.error('English pool name is mandatory field');
        errorFields.push(NAMES_FIELD);
        continue;
      }
      if (isEngDescriptionEmpty && !errorFields.includes(DESCRIPTIONS_FIELD)) {
        toast.error('English description is mandatory field');
        errorFields.push(DESCRIPTIONS_FIELD);
      }

      if (errorFields.includes(DESCRIPTIONS_FIELD) || errorFields.includes(NAMES_FIELD)) {
        continue;
      }

      const isFieldEmpty = !dataForRequest[fieldName].some(langDict => !!langDict.text);

      if (isFieldEmpty) {
        toast.error(`At least one "${fieldName}" field must be filled`);
        errorFields.push(fieldName);
      }

      if (fieldName === DESCRIPTIONS_FIELD && !errorFields.includes(NAMES_FIELD)) {
        const completedNames = dataForRequest[NAMES_FIELD].filter(el => !!el.text).map(
          el => el.lang
        );
        const completedDescriptions = dataForRequest[DESCRIPTIONS_FIELD].filter(el => !!el.text).map(
          el => el.lang
        );

        const isArraysEqual = JSON.stringify(completedNames) === JSON.stringify(completedDescriptions);
        const namesLength = completedNames.length;
        const descriptionsLength = completedDescriptions.length;

        if (namesLength > descriptionsLength) {
          errorFields.push(DESCRIPTIONS_FIELD);
          toast.error('Description is mandatory field');
        } else if (namesLength < descriptionsLength) {
          errorFields.push(NAMES_FIELD);
          toast.error('Name is mandatory field');
        } else if (!isArraysEqual) {
          errorFields.push(NAMES_FIELD);
          errorFields.push(DESCRIPTIONS_FIELD);
          toast.error('Name and description is mandatory field');
        }
      }

      dataForRequest[fieldName].forEach(langDict => {
        const errorField = validateField(
          isCreate,
          formState,
          fieldName,
          langDict.text,
          dataForRequest,
          langDict.lang
        );
        errorField && errorFields.push(errorField);
      });
    }

    delete dataForRequest[BRAND_FIELD];

    if (!errorFields.length) {
      setErrorFieldsState([]);
      submitUpdate(config);
    } else {
      setErrorFieldsState(errorFields);
    }
  }, [ formState, isCreate, status, submitUpdate ]);

  const { update: brandsUpdate } = useAxiosRequest(null, {
    onSuccess: (data: BrandsOrOriginsListRequestAnswer) =>
      originsOrBrandsOnSuccess(data, setBrandsState),
    onError: error => toast.error(error.message),
  });

  React.useEffect(() => {
    brandsUpdate({
      method: 'GET',
      url: API_BRAND_LIST,
    });
  }, [ brandsUpdate ]);

  const { update: originsUpdate } = useAxiosRequest(null, {
    onSuccess: (data: BrandsOrOriginsListRequestAnswer) =>
      originsOrBrandsOnSuccess(data, setOriginsState),
    onError: error => toast.error(error.message),
  });

  React.useEffect(() => {
    if (formState[BRAND_FIELD]) {
      originsUpdate({
        method: 'GET',
        url: API_ORIGINS_FIND,
        params: {
          brandId: formState[BRAND_FIELD],
        },
      });
    }
    // eslint-disable-next-line
  }, [formState[BRAND_FIELD]]);

  const brandsHandler = React.useCallback(
    (e, { value }) => {
      setFormState({ ...formState, [BRAND_FIELD]: value });
      setBackToPool(false);
    },
    [ formState ]
  );

  const originsHandler = React.useCallback(
    (e, { value, options }) => {
      const currency = options.filter(el => el.key === value)[0].currency;
      setFormState({
        ...formState,
        [ORIGIN_CURRENCY]: currency,
        [ORIGINS_FIELD]: [ value ],
      });
      setBackToPool(false);
    },
    [ formState ]
  );

  const startTimeHandler = React.useCallback(
    (name, value) => {
      const formatDate = value.toISOString().replace(/\.\S{0,3}Z/gm, 'Z');
      setFormState({ ...formState, [OPEN_DATE_FIELD]: formatDate });
      setBackToPool(false);
    },
    [ formState ]
  );

  const handler = React.useCallback(
    (e, { name, value }) => {
      setFormState({ ...formState, [name]: value });
      setBackToPool(false);
    },
    [ formState ]
  );

  const checkBoxHandler = React.useCallback(
    (e, data) => {
      setFormState({
        ...formState,
        [data.name]: data.checked,
      });
      setBackToPool(false);
    },
    [ formState ]
  );

  const prizeFundHandler = React.useCallback(
    (e, { name, value }) => {
      const isCorrectValue = !!value && !isNaN(Number(value));
      setErrorFieldsState(prev => prev.filter(el => el !== PRIZE_GUARANTEE_FIELD));
      setFormState(prev => {
        const cashList = prev[PRIZE_CASH_DISTRIBUTION_FIELD];
        const percents = [
          ...prev[PRIZE_DISTRIBUTION_FIELD].map((e, i) => {
            const denominator = isCorrectValue
              ? value
              : cashList.reduce((a, c) => Number(a) + Number(c));
            const percent = (cashList[i] / denominator) * 100;
            return percent.toFixed(2);
          })
        ];

        return {
          ...prev,
          [name]: value,
          [PRIZE_DISTRIBUTION_FIELD]: isCash ? percents : prev[PRIZE_DISTRIBUTION_FIELD],
        };
      });
      setBackToPool(false);
    },
    [ isCash ]
  );

  return (
    <div className="ipools-container">
      <Button
        icon
        className="return-button"
        labelPosition={isChanges && !isCreate ? 'left' : null}
        as={Link}
        to={-1}
      >
        <Icon name="arrow left" />
        {isChanges && !isCreate && <span>Exit without saving</span>}
      </Button>
      <div className="creation-grid">
        <Header textAlign="center" as="h1" className="create-pools-page__header">
          {isCreate ? 'Create pool' : `Edit pool ID_${id}`}
        </Header>
        <Form>
          <Grid>
            <Grid.Row className="form__section">
              <Grid.Column width={4} className="section__header-wrapper">
                <Header textAlign="left" as="h2" className="section__header">
                  Main info
                </Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <CreationFormField
                  label="Brands"
                  type={DROPDOWN}
                  options={brandsState}
                  inputWidth={10}
                  labelWidth={3}
                  name={BRAND_FIELD}
                  handleChange={brandsHandler}
                  error={errorFieldsState.includes(BRAND_FIELD)}
                  value={formState[BRAND_FIELD]}
                  disabled={disableNotDraftStatus}
                />
                <CreationFormField
                  label="Origins"
                  type={DROPDOWN}
                  options={originsState}
                  inputWidth={10}
                  labelWidth={3}
                  name={ORIGINS_FIELD}
                  handleChange={originsHandler}
                  disabled={!formState[BRAND_FIELD] || disableNotDraftStatus}
                  error={errorFieldsState.includes(ORIGINS_FIELD)}
                  value={formState[ORIGINS_FIELD][0]}
                />
                <LanguageTabs
                  formState={formState}
                  setFormState={setFormState}
                  setBackToPool={setBackToPool}
                  errorFieldsState={errorFieldsState}
                  languagesList={langsList}
                />
                <CreationFormField
                  label="Starting time"
                  type={DATEPICKER}
                  name={OPEN_DATE_FIELD}
                  inputWidth={13}
                  labelWidth={3}
                  handleChange={startTimeHandler}
                  error={errorFieldsState.includes(OPEN_DATE_FIELD)}
                  value={formState[OPEN_DATE_FIELD]}
                  disabled={disableNotDraftStatus}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="form__section">
              <Grid.Column width={4} className="section__header-wrapper">
                <Header textAlign="left" as="h2" className="section__header">
                  Max number of entries
                </Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <CreationFormField
                  label="Per player"
                  type={INPUT}
                  inputWidth={6}
                  labelWidth={3}
                  name={MAX_ENTRIES_PER_USER_FIELD}
                  handleChange={handler}
                  error={errorFieldsState.includes(MAX_ENTRIES_PER_USER_FIELD)}
                  value={formState[MAX_ENTRIES_PER_USER_FIELD]}
                  disabled={disableNotDraftStatus}
                />
                <CreationFormField
                  label="For the pool"
                  type={INPUT}
                  inputWidth={6}
                  labelWidth={3}
                  name={MAX_ENTRIES_PER_POOL_FIELD}
                  handleChange={handler}
                  error={errorFieldsState.includes(MAX_ENTRIES_PER_POOL_FIELD)}
                  value={formState[MAX_ENTRIES_PER_POOL_FIELD]}
                  disabled={disableNotDraftStatus}
                />
                <CreationFormField
                  label="Visible to unregistered"
                  type={TOGGLE}
                  name={VISIBLE_TO_UNREGISTER_FIELD}
                  inputWidth={6}
                  labelWidth={3}
                  handleChange={checkBoxHandler}
                  checked={formState[VISIBLE_TO_UNREGISTER_FIELD]}
                  disabled={disableNotDraftStatus}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="form__section">
              <Grid.Column width={4} className="section__header-wrapper">
                <Header textAlign="left" as="h2" className="section__header">
                  Prize distribution
                </Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <PrizePlacesComponent
                  prizes={formState[PRIZE_DISTRIBUTION_FIELD]}
                  formState={formState}
                  setFormState={setFormState}
                  error={errorFieldsState.includes(PRIZE_DISTRIBUTION_FIELD)}
                  disableNotDraftStatus={disableNotDraftStatus}
                  isCash={isCash}
                  setIsCash={setIsCash}
                  setErrorFieldsState={setErrorFieldsState}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="form__section section-payments-info">
              <Grid.Column width={4} className="section__header-wrapper">
                <Header textAlign="left" as="h2" className="section__header">
                  Payments info
                </Header>
              </Grid.Column>
              <Grid.Column width={12}>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column width={8}>
                      <CreationFormField
                        label="Entry bet"
                        type={INPUT}
                        labelWidth={6}
                        inputWidth={8}
                        name={ENTRY_BET_FIELD}
                        handleChange={handler}
                        error={errorFieldsState.includes(ENTRY_BET_FIELD)}
                        value={formState[ENTRY_BET_FIELD]}
                        disabled={disableNotDraftStatus}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <CreationFormField
                        label="Jackpot"
                        type={INPUT}
                        labelWidth={6}
                        inputWidth={8}
                        name={PRIZE_JACKPOT_FIELD}
                        handleChange={handler}
                        error={errorFieldsState.includes(PRIZE_JACKPOT_FIELD)}
                        value={formState[PRIZE_JACKPOT_FIELD]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column width={8}>
                      <CreationFormField
                        label="Commission"
                        type={INPUT}
                        labelWidth={6}
                        inputWidth={8}
                        name={COMMISSION_FIELD}
                        handleChange={handler}
                        isInPercents
                        error={errorFieldsState.includes(COMMISSION_FIELD)}
                        value={formState[COMMISSION_FIELD]}
                        disabled={disableNotDraftStatus}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <CreationFormField
                        label="Individual bonus"
                        type={INPUT}
                        labelWidth={6}
                        inputWidth={8}
                        name={PRIZE_BONUS_INDIVIDUAL_FIELD}
                        handleChange={handler}
                        error={errorFieldsState.includes(PRIZE_BONUS_INDIVIDUAL_FIELD)}
                        value={formState[PRIZE_BONUS_INDIVIDUAL_FIELD]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column width={8}>
                      <CreationFormField
                        label="Insurance commission"
                        type={INPUT}
                        labelWidth={6}
                        inputWidth={8}
                        name={INSURANCE_COMMISSION_FIELD}
                        handleChange={handler}
                        error={errorFieldsState.includes(INSURANCE_COMMISSION_FIELD)}
                        value={formState[INSURANCE_COMMISSION_FIELD]}
                        disabled={disableNotDraftStatus}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <CreationFormField
                        label="Total bonus"
                        type={INPUT}
                        labelWidth={6}
                        inputWidth={8}
                        name={PRIZE_BONUS_TOTAL_FIELD}
                        handleChange={handler}
                        error={errorFieldsState.includes(PRIZE_BONUS_TOTAL_FIELD)}
                        value={formState[PRIZE_BONUS_TOTAL_FIELD]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row width={8}>
                    <Grid.Column width={8}>
                      <CreationFormField
                        label="Prize fund guarantee"
                        type={INPUT}
                        labelWidth={6}
                        inputWidth={8}
                        name={PRIZE_GUARANTEE_FIELD}
                        handleChange={prizeFundHandler}
                        error={errorFieldsState.includes(PRIZE_GUARANTEE_FIELD)}
                        value={formState[PRIZE_GUARANTEE_FIELD]}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <CreationFormField
                        label="Origin currency"
                        type={INPUT}
                        labelWidth={6}
                        inputWidth={8}
                        name={ORIGIN_CURRENCY}
                        handleChange={() => null}
                        value={formState[ORIGIN_CURRENCY]}
                        disabled={disableNotDraftStatus}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <CreationFormField
                        label="Operator will fund shortfall"
                        type={TOGGLE}
                        name={OPERATOR_FUND_SHORTFALL_FIELD}
                        handleChange={checkBoxHandler}
                        checked={formState[OPERATOR_FUND_SHORTFALL_FIELD]}
                        disabled={disableNotDraftStatus}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <CreationFormField
                        label="Support double score"
                        type={TOGGLE}
                        name={DOUBLE_SCORE_FIELD}
                        handleChange={checkBoxHandler}
                        checked={formState[DOUBLE_SCORE_FIELD]}
                        disabled={disableNotDraftStatus}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="form__section">
              <Grid.Column textAlign="center">
                {isCreate && (
                  <Form.Button content="Create pool" onClick={handleSubmit} size="big" positive />
                )}
                {!isCreate && backToPool && (
                  <Button
                    icon="left arrow"
                    color="green"
                    size="big"
                    content="Back to pool"
                    as={Link}
                    to={-1}
                  />
                )}
                {!isCreate && !backToPool && (
                  <Form.Button
                    content="Save changes"
                    onClick={handleSubmit}
                    size="big"
                    positive
                    disabled={!isChanges}
                    loading={updateIsFetching}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    </div>
  );
};
