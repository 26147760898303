import { PoolVariablesType } from '../../types';

const initialState: PoolVariablesType = {
  actualId: 0,
  actualLanguage: 'en',
};

const CHANGE_ID = 'CHANGE_ID';
const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
const CHANGE_USER_ORIGIN = 'CHANGE_USER_ORIGIN';

export const poolVariablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ID:
      return { ...state, actualId: action.payload };
    case CHANGE_LANGUAGE:
      return { ...state, actualLanguage: action.payload };
    case CHANGE_USER_ORIGIN:
      return { ...state, originId: action.payload };
    default:
      return state;
  }
};

export const changeIdAction = payload => ({
  type: CHANGE_ID,
  payload,
});

export const changeLanguageAction = payload => ({
  type: CHANGE_LANGUAGE,
  payload,
});

export const changeUserOriginAction = payload => ({
  type: CHANGE_USER_ORIGIN,
  payload,
});
