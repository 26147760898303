import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { AxiosRequest } from '../../components/AxiosRequest';
import { EditForm } from '../../components/edit-pool-form/EditForm';
import { useAxiosRequest } from '../../components/use-axios-request/use-axios-request.es';
import { API_FIND_BY_ID, API_LANGUAGE_LIST } from '../../constants';
import { changePoolInfoAction, changePoolOpenDateAction } from '../../store/reducers/poolInfo';
import { LanguagesListType, ReduxStateType } from '../../types';

export const CreatePoolPage = () => {
  const state = useSelector((state: ReduxStateType) => state);
  const { actualId } = state.variables;

  if (!actualId)
    return (
      <AxiosRequest
        config={{
          method: 'GET',
          url: API_LANGUAGE_LIST,
        }}
      >
        {({ data }) => {
          const langsList = data.result;
          return <EditForm isCreate langsList={langsList} />;
        }}
      </AxiosRequest>
    );

  return (
    <AxiosRequest
      config={{
        method: 'GET',
        url: API_LANGUAGE_LIST,
      }}
    >
      {({ data }) => {
        const langsList = data.result;
        return <CreatePoolPageComponent langsList={langsList} poolId={actualId} />;
      }}
    </AxiosRequest>
  );
};

const CreatePoolPageComponent = ({
  langsList,
  poolId,
}: {
  langsList: LanguagesListType;
  poolId: string | number;
}) => {
  const dispatch = useDispatch();
  const config = React.useMemo(
    () => ({
      url: API_FIND_BY_ID,
      params: { id: poolId },
    }),
    [ poolId ]
  );

  const { isFetching, data } = useAxiosRequest(config, {
    onSuccess: dispatchOnSuccess,
    onError: error => toast.error(error.message),
  });

  function dispatchOnSuccess(data) {
    dispatch(changePoolInfoAction(data.result));
    dispatch(changePoolOpenDateAction(null));
  }

  if (isFetching || !data) return <Loader />;

  return <EditForm isCreate langsList={langsList} />;
};
