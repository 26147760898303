import React from 'react';

import cx from 'classnames';
import { Icon, Table } from 'semantic-ui-react';

import { FeedQuestionMarket } from './FeedQuestionMarket';
import { IPOOLS_DATE_FORMAT } from '../../../../constants';
import { FeedQuestionType } from '../../../../types';
import { formattingDate } from '../../../../utils';

export const FeedQuestionTableRow = ({ question }: { question: FeedQuestionType }) => {
  const [ isVisible, setIsVisible ] = React.useState(false);
  const localStartDate = formattingDate(question.startDate, IPOOLS_DATE_FORMAT);

  return (
    <Table.Row>
      <Table.Cell verticalAlign="top">{localStartDate}</Table.Cell>
      <Table.Cell>
        <div className="feed-question__info-cell">
          <span>{question.sportDescription}</span> /<span>{question.leagueDescription}</span>
          <br />
          <span className="feed-question__description">{question.description}</span>
          <span> ({question.feedCode})</span>
          {question.markets.length > 0 && (
            <div
              className={cx('button-up-down', { 'button-up-down_active': isVisible })}
              onClick={() => setIsVisible(!isVisible)}
            >
              <Icon name="caret down" />
            </div>
          )}
        </div>
        {question.markets.length > 0 &&
          isVisible &&
          question.markets.map(market => <FeedQuestionMarket key={market.id} market={market} />)}
      </Table.Cell>
    </Table.Row>
  );
};
