import React from 'react';

import cx from 'classnames';
import { Header, Input } from 'semantic-ui-react';

import {
  ANSWER_RESULT_OPTIONS,
  ANSWERS,
  ANSWERS_SCORES,
  ANSWERS_VAL_FROM,
  ANSWERS_VAL_RESULT,
  ANSWERS_VAL_TO,
  QUESTION_NEAREST_WIN_RANGE,
  QUESTION_TIEBREAK
} from '../../../../constants';
import { PoolQuestionStateType, PoolAnswerResult } from '../../../../types';

export const DeltaAnswerRow = ({
  answer,
  questionState,
  isEditable,
  setQuestionState,
  isSetResultEditable,
  errorsState,
}: {
  answer: {
    id: string;
    outcomeId: string;
    scores: string | number;
    result: PoolAnswerResult;
    descriptions: Array<{ lang: string; text: string }>;
  };
  questionState: PoolQuestionStateType;
  isEditable: boolean;
  setQuestionState: (questionState: (prevState) => PoolQuestionStateType) => void;
  isSetResultEditable: boolean;
  errorsState: Array<string>;
}) => {
  const [ voidId ] = ANSWER_RESULT_OPTIONS[2];
  const isWinRange = questionState.questionType === QUESTION_NEAREST_WIN_RANGE;
  const isTiebreak = questionState.questionType === QUESTION_TIEBREAK;
  const isVoid = questionState.answers[0].result === voidId;

  const inputOnChange = (event, data) => {
    setQuestionState(prevState => {
      const updateAnswers = prevState.answers.map(answerFromState => {
        if (answerFromState.id !== answer.id) return answerFromState;

        if (data.id === ANSWERS_SCORES && isWinRange) {
          return { ...answerFromState, [data.id]: data.value };
        }

        const value = !isNaN(Number(data.value)) ? Number(data.value) : answerFromState[data.id];
        return { ...answerFromState, [data.id]: value };
      });

      return {
        ...prevState,
        answers: updateAnswers,
      };
    });
  };

  return (
    <div className="question-answer-row question-answer-row_range">
      <div className="question-range-input-wrapper question-range-input-wrapper_min-max">
        <Header as="h5" content="Range" disabled={!isEditable} />
        <Input
          className="question-range-input question-range-input_small"
          placeholder="Min"
          id={ANSWERS_VAL_FROM}
          value={answer[ANSWERS_VAL_FROM]}
          onChange={inputOnChange}
          disabled={!isEditable}
          error={errorsState.includes(`${ANSWERS}_0_${ANSWERS_VAL_FROM}`)}
        />
        <span>{'—'}</span>
        <Input
          className="question-range-input question-range-input_small"
          placeholder="Max"
          id={ANSWERS_VAL_TO}
          value={answer[ANSWERS_VAL_TO]}
          onChange={inputOnChange}
          disabled={!isEditable}
          error={errorsState.includes(`${ANSWERS}_0_${ANSWERS_VAL_TO}`)}
        />
      </div>
      <div className="question-range-input-wrapper question-range-input-wrapper_points">
        {!isTiebreak && (
          <>
            <Header as="h5" content="Points for correct answer" disabled={!isEditable} />
            <Input
              className={cx(
                'question-range-input',
                isWinRange ? 'question-range-input_large' : 'question-range-input_medium'
              )}
              id={ANSWERS_SCORES}
              value={answer[ANSWERS_SCORES]}
              onChange={inputOnChange}
              disabled={!isEditable}
              error={errorsState.includes(`${ANSWERS}_0_${ANSWERS_SCORES}`)}
            />
          </>
        )}
      </div>
      <div className="question-range-input-wrapper question-range-input-wrapper_final">
        <Header as="h5" content="Final" disabled={!isSetResultEditable || isVoid} />
        <Input
          className="question-range-input question-range-input_small"
          id={ANSWERS_VAL_RESULT}
          value={answer[ANSWERS_VAL_RESULT]}
          onChange={inputOnChange}
          disabled={!isSetResultEditable || isVoid}
          error={errorsState.includes(`${ANSWERS}_0_${ANSWERS_VAL_RESULT}`)}
        />
      </div>
    </div>
  );
};
