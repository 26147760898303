import { FeedQuestionsSearchState } from '../../types';
import { getStartDate } from '../../utils';

const initialState: FeedQuestionsSearchState = {
  startDateFrom: getStartDate(),
  limit: 10,
};

const CHANGE_LIMIT = 'CHANGE_LIMIT';
const CHANGE_DATE_FROM = 'CHANGE_DATE_FROM';
const CHANGE_DATE_TO = 'CHANGE_DATE_TO';
const CHANGE_SEARCH_LINE = 'CHANGE_SEARCH_LINE';
const CHANGE_SPORT_ID = 'CHANGE_SPORT_ID';
const CHANGE_REGION_ID = 'CHANGE_REGION_ID';
const CHANGE_LEAGUE_ID = 'CHANGE_LEAGUE_ID';
const DEFAULT_SEARCH = 'DEFAULT_SEARCH';

export const feedQuestionSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LIMIT:
      return { ...state, limit: action.payload };
    case CHANGE_DATE_FROM:
      return { ...state, startDateFrom: action.payload };
    case CHANGE_DATE_TO:
      return { ...state, startDateTo: action.payload };
    case CHANGE_SEARCH_LINE:
      return { ...state, searchLine: action.payload };
    case CHANGE_SPORT_ID:
      return {
        ...state,
        sportId: action.payload,
        regionId: null,
        leagueId: null,
        limit: 10,
      };
    case CHANGE_REGION_ID:
      return {
        ...state,
        regionId: action.payload,
        leagueId: null,
        limit: 10,
      };
    case CHANGE_LEAGUE_ID:
      return {
        ...state,
        leagueId: action.payload,
        limit: 10,
      };
    case DEFAULT_SEARCH:
      return { ...initialState };
    default:
      return state;
  }
};

export const changeLimitSearchAction = payload => ({
  type: CHANGE_LIMIT,
  payload,
});

export const changeDateFromSearchAction = payload => ({
  type: CHANGE_DATE_FROM,
  payload,
});

export const changeDateToSearchAction = payload => ({
  type: CHANGE_DATE_TO,
  payload,
});

export const changeLineSearchAction = payload => ({
  type: CHANGE_SEARCH_LINE,
  payload,
});

export const changeSportSearchAction = payload => ({
  type: CHANGE_SPORT_ID,
  payload,
});

export const changeRegionSearchAction = payload => ({
  type: CHANGE_REGION_ID,
  payload,
});

export const changeLeagueSearchAction = payload => ({
  type: CHANGE_LEAGUE_ID,
  payload,
});

export const defaultSearchState = () => ({
  type: DEFAULT_SEARCH,
});
