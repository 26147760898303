/* eslint-disable */
import { useMemo, useReducer, useCallback, useEffect, useRef } from 'react';
import Axios from 'axios';
import qs from 'qs';

const Cache = new Map();
const CacheRequests = new Map();

let CachePolicy;

function init(_a) {
  const config = _a.config,
    cacheKey = _a.cacheKey,
    data = _a.data,
    prevConfig = _a.prevConfig,
    cachePolicy = _a.cachePolicy;
  const hasCache = typeof cacheKey === 'string' && Cache.has(cacheKey);
  return {
    data: cacheKey && hasCache ? Cache.get(cacheKey) : data,
    isFetching:
      config != null && (!hasCache || (hasCache && cachePolicy === CachePolicy.CacheAndNetwork)),
    config: config,
    error: null,
    requestId: 1,
    prevConfig: prevConfig,
  };
}
function reducer(state, action) {
  let config;
  let cacheKey;
  let cachePolicy;
  switch (action.type) {
    case 'manually set config': {
      let _a = action.payload;
      config = _a.config;
      cacheKey = _a.cacheKey;
      cachePolicy = _a.cachePolicy;
      if (config === state.config) {
        return state;
      }
      return init({
        config: config,
        cacheKey: cacheKey,
        prevConfig: state.prevConfig,
        data: state.data,
        cachePolicy: cachePolicy,
      });
    }
    case 'config changed': {
      const _b = action.payload;
      config = _b.config;
      cacheKey = _b.cacheKey;
      cachePolicy = _b.cachePolicy;
      if (config === state.prevConfig) {
        return state;
      }
      return init({
        config: config,
        cacheKey: cacheKey,
        prevConfig: config,
        data: state.data,
        cachePolicy: cachePolicy,
      });
    }
    case 'poll':
      return {
        config: state.config,
        data: state.data,
        prevConfig: state.prevConfig,
        isFetching: true,
        error: null,
        requestId: state.requestId + 1,
      };
    case 'fetched':
      return {
        config: state.config,
        prevConfig: state.prevConfig,
        requestId: state.requestId,
        data: action.payload,
        isFetching: false,
        error: null,
      };
    case 'error':
      return {
        config: state.config,
        prevConfig: state.prevConfig,
        requestId: state.requestId,
        data: state.data,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

(function (CachePolicy) {
  CachePolicy['NoCache'] = 'no-cache';
  CachePolicy['CacheFirst'] = 'cache-first';
  CachePolicy['CacheAndNetwork'] = 'cache-and-network';
})(CachePolicy || (CachePolicy = {}));
function getCacheKey(axiosConfig, cachePolicy) {
  if (axiosConfig && cachePolicy !== CachePolicy.NoCache) {
    return typeof axiosConfig === 'string' ? axiosConfig : qs(axiosConfig.url, axiosConfig.params);
  }
  return null;
}
function sendRequest(config, cacheKey) {
  const source = Axios.CancelToken.source();
  const axiosConfig = {
    cancelToken: source.token,
  };
  if (typeof config === 'string') {
    axiosConfig.url = config;
    axiosConfig.method = 'GET';
  } else {
    Object.assign(axiosConfig, config);
  }
  let request;
  if (cacheKey) {
    const maybeRequest = CacheRequests.get(cacheKey);
    if (maybeRequest) {
      request = maybeRequest;
    } else {
      request = Axios(axiosConfig);
      CacheRequests.set(cacheKey, request);
    }
  } else {
    request = Axios(axiosConfig);
  }
  return {
    request: request.then(
      function (response) {
        if (cacheKey) {
          Cache.set(cacheKey, response.data);
          CacheRequests.delete(cacheKey);
        }
        return response;
      },
      function (error) {
        if (cacheKey) {
          CacheRequests.delete(cacheKey);
        }
        return Promise.reject(error);
      }
    ),
    cancel: source.cancel,
  };
}

function useAxiosRequest(axiosConfig, _a) {
  const _b = _a === void 0 ? {} : _a,
    _c = _b.cache,
    cachePolicy = _c === void 0 ? CachePolicy.NoCache : _c,
    _d = _b.pollInterval,
    pollInterval = _d === void 0 ? 0 : _d,
    onSuccess = _b.onSuccess,
    onError = _b.onError;
  const cacheKey = useMemo(
    function () {
      return getCacheKey(axiosConfig, cachePolicy);
    },
    [axiosConfig, cachePolicy]
  );
  const initialValue = {
    config: axiosConfig,
    cacheKey: cacheKey,
    data: undefined,
    prevConfig: axiosConfig,
    cachePolicy: cachePolicy,
  };
  const _e = useReducer(reducer, initialValue, init),
    state = _e[0],
    dispatch = _e[1];
  const updateConfig = useCallback(
    function (config) {
      dispatch({
        type: 'config changed',
        payload: {
          config: config,
          cacheKey: cacheKey,
          cachePolicy: cachePolicy,
        },
      });
    },
    [dispatch, cacheKey, cachePolicy]
  );
  const dispatchManullySetConfig = useCallback(
    function (config) {
      dispatch({
        type: 'manually set config',
        payload: {
          config: config,
          cacheKey: cacheKey,
          cachePolicy: cachePolicy,
        },
      });
    },
    [dispatch, cacheKey, cachePolicy]
  );
  const dispatchFetched = useCallback(
    function (data) {
      dispatch({
        type: 'fetched',
        payload: data,
      });
    },
    [dispatch]
  );
  const dispatchError = useCallback(
    function (error) {
      dispatch({
        type: 'error',
        payload: error,
      });
    },
    [dispatch]
  );
  const dispatchPoll = useCallback(
    function () {
      dispatch({
        type: 'poll',
      });
    },
    [dispatch]
  );
  if (state.prevConfig !== axiosConfig) {
    updateConfig(axiosConfig);
  }
  useEffect(
    function () {
      if (pollInterval > 0 && !state.isFetching) {
        const timeoutId_1 = setTimeout(dispatchPoll, pollInterval);
        return function () {
          clearTimeout(timeoutId_1);
        };
      }
    },
    [state.isFetching, pollInterval, dispatchPoll]
  );
  const onSuccessRef = useRef(onSuccess);
  const onErrorRef = useRef(onError);
  useEffect(function () {
    onSuccessRef.current = onSuccess;
    onErrorRef.current = onError;
  });
  useEffect(
    function () {
      if (state.config && state.isFetching) {
        let check_1 = true;
        const _a = sendRequest(state.config, cacheKey),
          request = _a.request,
          cancel_1 = _a.cancel;
        request
          .then(function (response) {
            if (check_1) {
              dispatchFetched(response.data);
              if (onSuccessRef.current) {
                onSuccessRef.current(response.data);
              }
            }
          })
          .catch(function (error) {
            if (check_1 && !Axios.isCancel(error)) {
              dispatchError(error);
              if (onErrorRef.current) {
                onErrorRef.current(error);
              }
            }
          });
        return function () {
          check_1 = false;
          if (cacheKey) {
            cancel_1();
          }
        };
      }
    },
    [state.config, state.isFetching, state.requestId, cacheKey]
  );
  return {
    isFetching: state.isFetching,
    requestId: state.requestId,
    data: state.data,
    error: state.error,
    refresh: dispatchPoll,
    update: dispatchManullySetConfig,
    config: state.config,
  };
}

export { Cache, CachePolicy, CacheRequests, useAxiosRequest };
