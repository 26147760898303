import React from 'react';

import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { Card } from 'semantic-ui-react';

import { AnswerRow } from './AnswerRow';
import { DeltaAnswerRow } from './DeltaAnswerRow';
import { FinishQuestionRow } from './FinishQuestionRow';
import { QuestionHeaderRow } from './QuestionHeaderRow';
import {
  ANSWER_RESULT_UNKNOWN_OPTION,
  POOL_STATUS_ENUM,
  PoolStatusEnum,
  QUESTION_NEAREST_WIN_OUTCOME,
  QUESTION_NEAREST_WIN_RANGE,
  QUESTION_TIEBREAK
} from '../../../../constants';
import { PoolQuestionType, ReduxStateType } from '../../../../types';

export const PoolQuestionCard = ({ question }: { question: PoolQuestionType }) => {
  const state = useSelector((state: ReduxStateType) => state);
  const { actualLanguage } = state.variables;
  const { status } = state.poolInfo;

  const initialState = React.useMemo(
    () => ({
      ...question,
      answers: [
        ...question.answers.map(answer => {
          const updatedScores =
            question.questionType === QUESTION_NEAREST_WIN_RANGE
              ? answer.scores?.toString().replace(/,/g, ', ')
              : Number(answer.scores?.toString());
          return {
            ...answer,
            scores: updatedScores,
          };
        })
      ],
    }),
    [ question ]
  );

  const [ questionState, setQuestionState ] = React.useState(initialState);
  const [ errorsState, setErrorsState ] = React.useState([]);

  const isChange = !isEqual(questionState, initialState);
  const isDraft = status === POOL_STATUS_ENUM.DRAFT;
  const cutoffDate = new Date(questionState.cutoffDate);
  const isLive = cutoffDate < new Date();
  const isEnded = question.answers.every(answer => answer.result !== ANSWER_RESULT_UNKNOWN_OPTION);
  const isQuestionRange = [
    QUESTION_TIEBREAK,
    QUESTION_NEAREST_WIN_OUTCOME,
    QUESTION_NEAREST_WIN_RANGE
  ].includes(questionState.questionType);

  const isSetResultEditable =
    //@ts-ignore
    [ POOL_STATUS_ENUM.LIVE, POOL_STATUS_ENUM.COMPLETED ].includes(status) && isLive;

  const isVoidEditable =
    // @ts-ignore
    [ POOL_STATUS_ENUM.LIVE, POOL_STATUS_ENUM.COMPLETED, PoolStatusEnum.OPEN ].includes(status);

  const questionInputOnChange = React.useCallback(
    (event, data, arrOfDescriptions) => {
      const updateDataForQuestions = arrOfDescriptions.map(el =>
        el.lang === actualLanguage ? { lang: actualLanguage, text: data.value } : el
      );
      setQuestionState(prevState => ({
        ...prevState,
        [data.id]: updateDataForQuestions,
      }));
    },
    [ setQuestionState, actualLanguage ]
  );

  return (
    <Card className="question-card">
      <QuestionHeaderRow
        questionState={questionState}
        isEditable={isDraft}
        setQuestionState={setQuestionState}
        isLive={isLive}
        questionInputOnChange={questionInputOnChange}
        isEnded={isEnded}
        errorsState={errorsState}
        isRange={isQuestionRange}
      />

      {questionState.answers.map((answer, index) =>
        isQuestionRange ? (
          <DeltaAnswerRow
            key={answer.id}
            answer={answer}
            questionState={questionState}
            isEditable={isDraft}
            setQuestionState={setQuestionState}
            isSetResultEditable={isSetResultEditable}
            errorsState={errorsState}
          />
        ) : (
          <AnswerRow
            key={answer.id}
            answer={answer}
            questionState={questionState}
            setQuestionState={setQuestionState}
            isEditable={isDraft}
            isSetResultEditable={isSetResultEditable}
            isVoidEditable={isVoidEditable}
            errorsState={errorsState}
            index={index}
          />
        )
      )}

      <FinishQuestionRow
        questionState={questionState}
        setQuestionState={setQuestionState}
        questionInputOnChange={questionInputOnChange}
        isEditable={isDraft}
        isVoidEditable={isVoidEditable}
        errorsState={errorsState}
        setErrorsState={setErrorsState}
        isChange={isChange}
        isRange={isQuestionRange}
      />
    </Card>
  );
};
