import React from 'react';

import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { Input } from 'semantic-ui-react';

import { changeSearchAction } from '../../store/reducers/poolTable';

export const SearchPoolTableComponent = () => {
  const dispatch = useDispatch();
  const [ value, setValue ] = React.useState('');

  const sendRequest = React.useCallback(
    (value: string) => {
      dispatch(changeSearchAction(value));
    },
    [ dispatch ]
  );

  const debouncedChangeHandler = React.useMemo(() => {
    return debounce(sendRequest, 1000);
  }, [ sendRequest ]);

  function poolIdFilterOnChange(e) {
    e.preventDefault();

    const newValue = e.target.value;
    setValue(newValue);

    if (newValue.length > 2) {
      debouncedChangeHandler(newValue);
    } else {
      debouncedChangeHandler(null);
    }
  }

  return <Input value={value} onChange={poolIdFilterOnChange} icon="search" placeholder="Search..." />;
};
