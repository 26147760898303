export const IPOOLS_PATH = '/ipools';
export const COLOR_PURPLE = '#800080';

/** API */
export const API_IPOOLS = '/v1/DealerAPI/pool';
export const API_POOL_LIST = `${API_IPOOLS}/list`;
export const API_POOL_CREATE = `${API_IPOOLS}/create`;
export const API_POOL_UPDATE = `${API_IPOOLS}/update`;
export const API_BRAND_LIST = `${API_IPOOLS}/brand/list`;
export const API_LANGUAGE_LIST = `${API_IPOOLS}/language/list`;
export const API_QUESTION_ADD = `${API_IPOOLS}/question/add`;
export const API_QUESTION_UPDATE = `${API_IPOOLS}/question/update`;
export const API_QUESTION_REMOVE = `${API_IPOOLS}/question/remove`;
export const API_ENTRIES = `${API_IPOOLS}/entries`;
export const API_ENTRY_UPDATE = `${API_IPOOLS}/entry/update`;
export const API_SPORTS_FIND = `${API_IPOOLS}/sports/find`;
export const API_REGIONS_FIND = `${API_IPOOLS}/regions/find`;
export const API_LEAGUES_FIND = `${API_IPOOLS}/leagues/find`;
export const API_ANSWER_ADD = `${API_IPOOLS}/answer/add`;
export const API_ANSWER_REMOVE = `${API_IPOOLS}/answer/remove`;
export const API_ORIGINS_FIND = `${API_IPOOLS}/origins/find`;
export const API_FIND_BY_ID = `${API_IPOOLS}/findById`;
export const API_EVENTS_FIND = `${API_IPOOLS}/events/find`;
export const API_POOL_STATISTICS = `${API_IPOOLS}/statistics`;

export enum PoolStatusEnum {
  UNKNOWN = 'POOL_STATUS_UNKNOWN',
  DRAFT = 'DRAFT',
  AVAILABLE = 'AVAILABLE',
  OPEN = 'OPEN',
  LIVE = 'LIVE',
  COMPLETED = 'COMPLETED',
  SETTLED = 'SETTLED',
  VOIDED = 'VOIDED',
  SETTLE_IN_PROGRESS = 'SETTLE_IN_PROGRESS',
  SETTLED_WITH_ERRORS = 'SETTLED_WITH_ERRORS',
}

export const POOL_STATUS_ENUM = {
  UNKNOWN: PoolStatusEnum.UNKNOWN,
  DRAFT: PoolStatusEnum.DRAFT,
  AVAILABLE: PoolStatusEnum.AVAILABLE,
  OPEN: PoolStatusEnum.OPEN,
  LIVE: PoolStatusEnum.LIVE,
  COMPLETED: PoolStatusEnum.COMPLETED,
  SETTLED: PoolStatusEnum.SETTLED,
  VOIDED: PoolStatusEnum.VOIDED,
  SETTLE_IN_PROGRESS: PoolStatusEnum.SETTLE_IN_PROGRESS,
  SETTLED_WITH_ERRORS: PoolStatusEnum.SETTLED_WITH_ERRORS,
};

export const POOL_ID = 'id';
export const BRAND_FIELD = 'brand';
export const ORIGINS_FIELD = 'originIds';
export const DESCRIPTIONS_FIELD = 'descriptions';
export const NAMES_FIELD = 'names';
export const PRIZE_JACKPOT_FIELD = 'prizeJackpot';
export const OPEN_DATE_FIELD = 'openDate';
export const MAX_ENTRIES_PER_USER_FIELD = 'maxEntriesPerUser';
export const MAX_ENTRIES_PER_POOL_FIELD = 'maxEntriesPerPool';
export const PRIZE_DISTRIBUTION_FIELD = 'prizeDistribution';
export const PRIZE_CASH_DISTRIBUTION_FIELD = 'prizeCashDistribution';
export const ENTRY_BET_FIELD = 'entryBet';
export const COMMISSION_FIELD = 'commission';
export const PRIZE_BONUS_INDIVIDUAL_FIELD = 'prizeBonusIndividual';
export const PRIZE_BONUS_TOTAL_FIELD = 'prizeBonusTotal';
export const INSURANCE_COMMISSION_FIELD = 'insuranceCommission';
export const OPERATOR_FUND_SHORTFALL_FIELD = 'operatorFundShortfall';
export const VISIBLE_TO_UNREGISTER_FIELD = 'isVisibleToUnregistered';
export const DOUBLE_SCORE_FIELD = 'isDoubleScore';
export const PRIZE_GUARANTEE_FIELD = 'prizeGuarantee';
export const NUMBER_OF_PLACES = 'numberOfPlaces';
export const ORIGIN_CURRENCY = 'currencyCode';
export const POOL_STATUS_ALL = 'ALL';
export const POOL_STATUS_ALL_DESCRIPTION = 'All statuses';
export const POOL_ORIGINS_ALL = 'ALL';
export const IS_CASH = 'isCash';

export const TOGGLE = 'toggle';
export const DROPDOWN = 'dropdown';
export const INPUT = 'input';
export const DATEPICKER = 'datepicker';
export const ADD_BUTTON = 'addButton';
export const TEXT_AREA = 'textArea';
export const DROPDOWN_MULTIPLY = 'dropdownMultiply';
export const INPUT_WITH_DELETE = 'inputWithDelete';
export const TEXT_EDITOR = 'textEditor';

export enum AnswerResultsEnum {
  WIN = 'WIN',
  LOSE = 'LOSE',
  VOID = 'VOID',
  VALUE = 'VALUE',
}

export const ANSWER_RESULT_OPTIONS = [
  [ AnswerResultsEnum.WIN, 'Win' ],
  [ AnswerResultsEnum.LOSE, 'Lose' ],
  [ AnswerResultsEnum.VOID, 'Void' ]
];

export const ENTRIES_BONUS_OPTIONS = [
  { label: 'Jackpot', action: 'JACKPOT', className: 'jackpot' },
  { label: 'Bonus', action: 'BONUS', className: 'bonus' },
  { label: 'Void', action: 'VOID', className: 'void' }
];

export const QUESTION_WIN_WIN = 'QT_WIN_WIN';
export const QUESTION_WIN_DRAW_WIN = 'QT_WIN_DRAW_WIN';
export const QUESTION_MULTIPLY = 'QT_MULTIPLE_CHOICE';
export const QUESTION_TIEBREAK = 'QT_NEAREST_TIEBREAK';
export const QUESTION_NEAREST_WIN_OUTCOME = 'QT_NEAREST_WIN_OUTCOME';
export const QUESTION_NEAREST_WIN_RANGE = 'QT_NEAREST_WIN_RANGE';

export const ANSWER_RESULT_UNKNOWN_OPTION = 'ANSWER_RESULT_UNKNOWN';

export const IPOOLS_DATE_FORMAT = 'dd.MM.yyyy HH:mm';

export const MANUAL_MARKET_ID = '0';

export const LEAGUE_DESCRIPTION = 'leagueDescriptions';
export const QUESTION_HEADER_DESCRIPTION = 'descriptions';
export const QUESTION_INFO_DESCRIPTION = 'infoDescriptions';
export const ANSWERS = 'answers';
export const ANSWERS_DESCRIPTION = 'descriptions';
export const ANSWERS_SCORES = 'scores';
export const ANSWERS_VAL_FROM = 'valueFrom';
export const ANSWERS_VAL_TO = 'valueTo';
export const ANSWERS_VAL_RESULT = 'resultValue';
export const CUTOFF_DATE = 'cutoffDate';
export const FEED_MARKET_CUTOFF_DATE = 'feedMarketCutoffDate';

export const QUESTIONS = 'Questions';
export const ENTRIES = 'Entries';

export const DEFAULT_LANGUAGE_OBJECT = {
  key: 'en',
  text: 'English',
  value: 'en',
};

export const STATUSES_FOR_CHANGE = {
  [POOL_STATUS_ENUM.UNKNOWN]: Object.keys(POOL_STATUS_ENUM),
  [POOL_STATUS_ENUM.DRAFT]: [ POOL_STATUS_ENUM.AVAILABLE, POOL_STATUS_ENUM.VOIDED ],
  [POOL_STATUS_ENUM.AVAILABLE]: [ POOL_STATUS_ENUM.DRAFT, POOL_STATUS_ENUM.VOIDED ],
  [POOL_STATUS_ENUM.OPEN]: [ POOL_STATUS_ENUM.DRAFT, POOL_STATUS_ENUM.VOIDED ],
  [POOL_STATUS_ENUM.LIVE]: [ POOL_STATUS_ENUM.VOIDED ],
  [POOL_STATUS_ENUM.COMPLETED]: [ POOL_STATUS_ENUM.SETTLED, POOL_STATUS_ENUM.VOIDED ],
  [POOL_STATUS_ENUM.SETTLED_WITH_ERRORS]: [ POOL_STATUS_ENUM.SETTLED, POOL_STATUS_ENUM.VOIDED ],
  [POOL_STATUS_ENUM.SETTLE_IN_PROGRESS]: [ POOL_STATUS_ENUM.VOIDED ],
  [POOL_STATUS_ENUM.SETTLED]: [ POOL_STATUS_ENUM.VOIDED ],
  [POOL_STATUS_ENUM.VOIDED]: [],
};

export const FEED_SEARCH_TIME_TYPE = 'dd MMM yyyy HH:mm';

export const ASCENDING = 'ascending';
export const DESCENDING = 'descending';

export const KEY_CODE_OF_ENTER = 13;
export const KEY_CODE_OF_TAB = 9;

export const OFFSIDE_GAMING_ID = 1;

const MANUAL_BUTTONS = {
  [QUESTION_WIN_WIN]: { type: QUESTION_WIN_WIN, description: 'Win/WIn' },
  [QUESTION_WIN_DRAW_WIN]: { type: QUESTION_WIN_DRAW_WIN, description: 'Win/Draw/WIn' },
  [QUESTION_MULTIPLY]: { type: QUESTION_MULTIPLY, description: 'Multiple choice' },
  [QUESTION_TIEBREAK]: { type: QUESTION_TIEBREAK, description: 'Tiebreak' },
  [QUESTION_NEAREST_WIN_OUTCOME]: {
    type: QUESTION_NEAREST_WIN_OUTCOME,
    description: 'Nearest win outcome',
  },
  [QUESTION_NEAREST_WIN_RANGE]: { type: QUESTION_NEAREST_WIN_RANGE, description: 'Nearest win range' },
};

export const BUTTONS_DEFAULT = [
  MANUAL_BUTTONS[QUESTION_WIN_WIN],
  MANUAL_BUTTONS[QUESTION_WIN_DRAW_WIN],
  MANUAL_BUTTONS[QUESTION_MULTIPLY],
  MANUAL_BUTTONS[QUESTION_TIEBREAK],
  MANUAL_BUTTONS[QUESTION_NEAREST_WIN_OUTCOME],
  MANUAL_BUTTONS[QUESTION_NEAREST_WIN_RANGE]
];

export const BUTTONS_SIMPLE = [ MANUAL_BUTTONS[QUESTION_WIN_WIN], MANUAL_BUTTONS[QUESTION_WIN_DRAW_WIN] ];

export const BUTTONS_MULTIPLY = [
  MANUAL_BUTTONS[QUESTION_MULTIPLY],
  MANUAL_BUTTONS[QUESTION_TIEBREAK],
  MANUAL_BUTTONS[QUESTION_NEAREST_WIN_OUTCOME],
  MANUAL_BUTTONS[QUESTION_NEAREST_WIN_RANGE]
];
