import React from 'react';

import axios from 'axios';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Header, Input } from 'semantic-ui-react';

import { useAxiosRequest } from '../../../../components/use-axios-request/use-axios-request.es';
import {
  ANSWER_RESULT_OPTIONS,
  ANSWER_RESULT_UNKNOWN_OPTION,
  AnswerResultsEnum,
  ANSWERS,
  API_ANSWER_ADD,
  API_QUESTION_UPDATE,
  CUTOFF_DATE,
  FEED_MARKET_CUTOFF_DATE,
  LEAGUE_DESCRIPTION,
  MANUAL_MARKET_ID,
  POOL_STATUS_ENUM,
  QUESTION_HEADER_DESCRIPTION,
  QUESTION_MULTIPLY,
  QUESTION_NEAREST_WIN_RANGE
} from '../../../../constants';
import { PoolQuestionStateType, ReduxStateType } from '../../../../types';
import { switchDescription, updatePoolInfo } from '../../../../utils';

export const FinishQuestionRow = ({
  questionState,
  setQuestionState,
  questionInputOnChange,
  isEditable,
  errorsState,
  setErrorsState,
  isVoidEditable,
  isChange,
  isRange,
}: {
  questionState: PoolQuestionStateType;
  setQuestionState: (questionState: (prevState) => PoolQuestionStateType) => void;
  questionInputOnChange: (data, value, arrOfDescriptions) => void;
  isEditable: boolean;
  errorsState: Array<string>;
  setErrorsState: (value: Array<string>) => void;
  isVoidEditable: boolean;
  isChange: boolean;
  isRange: boolean;
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { status, isPoolWithMultiplyChoice } = state.poolInfo;
  const { actualId: poolId, actualLanguage } = state.variables;
  const [ voidId, voidLabel ] = ANSWER_RESULT_OPTIONS[2];

  const answersQuantity = questionState.answers.length;
  const isMultiplyQuestion = questionState.questionType === QUESTION_MULTIPLY;
  const isVoid = questionState.answers[0].result === voidId;

  const actualValue = React.useMemo(() => {
    return switchDescription(questionState.leagueDescriptions, actualLanguage);
  }, [ actualLanguage, questionState.leagueDescriptions ]);

  const { update, isFetching } = useAxiosRequest(null, {
    onSuccess: () => {
      updatePoolInfo({ poolId, dispatch });
      toast.success('Question changes were successfully changed');
    },
    onError: data => toast.error(data.message),
  });

  const saveChanges = React.useCallback(() => {
    const dataForRequest = {
      ...questionState,
      [ANSWERS]: [
        ...questionState.answers.map(answer => {
          const updatedScores =
            typeof answer.scores === 'string'
              ? answer.scores.split(',').map(s => Number(s))
              : [ answer.scores ];

          return {
            ...answer,
            scores: updatedScores,
          };
        })
      ],
    };

    if (status === POOL_STATUS_ENUM.DRAFT) {
      const errorFields = [];
      const isCutoffDateOfFeedIsValid = () => {
        const cutoffDate = Number(new Date(dataForRequest[CUTOFF_DATE]));
        const feedMarketCutoffDate = Number(new Date(dataForRequest[FEED_MARKET_CUTOFF_DATE]));
        return cutoffDate <= feedMarketCutoffDate;
      };

      for (const fieldName in dataForRequest) {
        if (fieldName === QUESTION_HEADER_DESCRIPTION) {
          dataForRequest.descriptions.forEach(el => {
            if (!el.text) {
              toast.error('Description is mandatory field');
              errorFields.push(fieldName);
            } else if (el.text.length < 5 || el.text.length > 100) {
              toast.error('Description must be more than 5 and less than 100 symbols');
              errorFields.push(fieldName);
            }
          });
        } else if (fieldName === LEAGUE_DESCRIPTION) {
          dataForRequest.leagueDescriptions.forEach(el => {
            if (el.text.length > 100) {
              toast.error('League description must be less than 100 symbols');
              errorFields.push(fieldName);
            }
          });
        } else if (fieldName === ANSWERS) {
          dataForRequest.answers.forEach((answer, index) => {
            const { scores, valueFrom, valueTo, descriptions } = answer;

            /**  Validation for RANGE questions*/
            if (isRange) {
              /** Min-max value validation */
              if (valueFrom > 1000) {
                toast.error('Min value must be less than 1000');
                errorFields.push(`${fieldName}_${index}_valueFrom`);
              }
              if (valueTo > 1000) {
                toast.error('Max value must less than 1000');
                errorFields.push(`${fieldName}_${index}_valueTo`);
              }
              if (valueFrom >= valueTo) {
                toast.error('Min value must be more than max value');
                errorFields.push(`${fieldName}_${index}_valueFrom`);
              }
            }

            /** Scores validation */
            if (scores.length < 1) {
              toast.error('Score is mandatory field');
              errorFields.push(`${fieldName}_${index}_scores`);
            } else if (scores.length > 1 && questionState.questionType !== QUESTION_NEAREST_WIN_RANGE) {
              toast.error('Score must be a number');
              errorFields.push(`${fieldName}_${index}_scores`);
            } else {
              scores.forEach(score => {
                if (isNaN(score)) {
                  toast.error('Score must be a number');
                  errorFields.push(`${fieldName}_${index}_scores`);
                } else if (score < 1 || score > 100) {
                  toast.error('Score must be more than 1 and less than 100');
                  errorFields.push(`${fieldName}_${index}_scores`);
                } else if (!Number.isInteger(score)) {
                  toast.error('Score must be integer');
                  errorFields.push(`${fieldName}_${index}_scores`);
                }
              });
            }

            /** Descriptions validation */
            if (!isRange) {
              descriptions.forEach(el => {
                if (!el.text) {
                  toast.error('Answer description is mandatory field');
                  errorFields.push(`${fieldName}_${index}_description`);
                } else if (el.text.length > 100) {
                  toast.error('Answer must be less than 100 symbols');
                  errorFields.push(`${fieldName}_${index}_description`);
                }
              });
            }
          });
        } else if (fieldName === CUTOFF_DATE) {
          if (!dataForRequest[CUTOFF_DATE]) {
            toast.error('Starting time is mandatory field');
            errorFields.push(fieldName);
          }
          if (dataForRequest.marketId !== MANUAL_MARKET_ID && !isCutoffDateOfFeedIsValid()) {
            toast.error('Wrong date. Cutoff date can\'t be later then Feed Market Cutoff Date');
            errorFields.push(fieldName);
          }
          if (dataForRequest.marketId === MANUAL_MARKET_ID) {
            const startDate = new Date(dataForRequest[CUTOFF_DATE]);
            const currentDate = new Date();
            if (currentDate > startDate) {
              toast.error('Starting time must be in future');
              errorFields.push(fieldName);
            }
          }
        }
      }

      setErrorsState(errorFields);
      if (errorFields.length > 0) return;
    } else if (status === POOL_STATUS_ENUM.LIVE && isPoolWithMultiplyChoice) {
      const errorFields = [];
      const { resultValue } = dataForRequest.answers[0];

      if (resultValue > 1000) {
        toast.error('Max value must less than 1000');
        errorFields.push(`${ANSWERS}_0_resultValue`);
      }

      setErrorsState(errorFields);
      if (errorFields.length > 0) return;
    }

    delete dataForRequest.questionType;
    delete dataForRequest.marketId;
    dataForRequest.answers = dataForRequest.answers.map(answer => {
      const updateAnswer = { ...answer };
      delete updateAnswer.outcomeId;
      if (isRange && status === POOL_STATUS_ENUM.LIVE) {
        updateAnswer.result = AnswerResultsEnum.VALUE;
      }
      return updateAnswer;
    });

    update({
      method: 'PUT',
      url: API_QUESTION_UPDATE,
      data: {
        ...dataForRequest,
      },
    });
  }, [ questionState, status, isPoolWithMultiplyChoice, update, setErrorsState, isRange ]);

  const addOutcome = React.useCallback(() => {
    const dataForRequest = {
      questionId: questionState.id,
    };
    axios
      .post(API_ANSWER_ADD, dataForRequest)
      .then(response => response.data)
      .then(data => data.result)
      .then(res => {
        updatePoolInfo({ poolId, dispatch });
        setQuestionState(prevState => {
          const newAnswer = { ...res, result: ANSWER_RESULT_UNKNOWN_OPTION };
          const updateAnswers = [ ...prevState.answers, newAnswer ];
          return { ...prevState, answers: updateAnswers };
        });
      });
  }, [ dispatch, poolId, questionState.id, setQuestionState ]);

  const resultSelectorOnClick = React.useCallback(() => {
    setQuestionState(prevState => {
      const updatedAnswers = prevState.answers.map(answerFromState => {
        return {
          ...answerFromState,
          result: voidId,
        };
      });
      return {
        ...prevState,
        answers: updatedAnswers,
      };
    });
  }, [ setQuestionState, voidId ]);

  return (
    <div className="question-finish-row">
      <div className="question-finish-row__add-outcome-section">
        {isMultiplyQuestion && (
          <>
            <Button icon="plus" onClick={addOutcome} disabled={!isEditable || answersQuantity >= 32} />
            <Header
              as="h5"
              content="Add outcome"
              className="question-finish-row__header_with-btn"
              disabled={!isEditable || answersQuantity >= 32}
            />
          </>
        )}
        {!isMultiplyQuestion && !isRange && (
          <>
            <Header as="h5" content="League" className="question-finish-row__header" />
            <Input
              className="question-finish-row__league-input"
              size="small"
              value={actualValue}
              disabled={!isEditable}
              id={LEAGUE_DESCRIPTION}
              onChange={(e, data) => questionInputOnChange(e, data, questionState.leagueDescriptions)}
              error={errorsState.includes(LEAGUE_DESCRIPTION)}
            />
          </>
        )}
      </div>
      <div className="question-finish-row__button-section">
        {isPoolWithMultiplyChoice && !isMultiplyQuestion && (
          <Button
            size="mini"
            basic={!isVoid}
            className={cx('question-finish-row__void-button', {
              'question-finish-row__void-button_active': isVoid,
            })}
            disabled={!isVoidEditable}
            onClick={resultSelectorOnClick}
          >
            {voidLabel}
          </Button>
        )}
        <Button
          size="tiny"
          onClick={saveChanges}
          disabled={!isChange}
          loading={isFetching}
          content="Save"
        />
      </div>
    </div>
  );
};
