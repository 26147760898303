import React from 'react';

import { Image, Loader, Segment, Table } from 'semantic-ui-react';

export const FeedQuestionSkeleton = () => (
  <Table.Row>
    <Table.Cell colSpan={2} style={{ padding: 0 }}>
      <Segment style={{ border: 0, borderRadius: 0 }}>
        <Loader disabled />
        <Image
          src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png"
          style={{ maxWidth: '100%' }}
        />
        <br />
        <Image
          src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png"
          style={{ maxWidth: '100%' }}
        />
        <br />
        <Image
          src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png"
          style={{ maxWidth: '100%' }}
        />
        <br />
        <Image
          src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png"
          style={{ maxWidth: '100%' }}
        />
        <br />
        <Image
          src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png"
          style={{ maxWidth: '100%' }}
        />
        <br />
        <Image
          src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png"
          style={{ maxWidth: '100%' }}
        />
      </Segment>
    </Table.Cell>
  </Table.Row>
);
