import { toast } from 'react-toastify';

import {
  BRAND_FIELD,
  COMMISSION_FIELD,
  DESCRIPTIONS_FIELD,
  DOUBLE_SCORE_FIELD,
  ENTRY_BET_FIELD,
  INSURANCE_COMMISSION_FIELD,
  MAX_ENTRIES_PER_POOL_FIELD,
  MAX_ENTRIES_PER_USER_FIELD,
  NAMES_FIELD,
  NUMBER_OF_PLACES,
  OPEN_DATE_FIELD,
  OPERATOR_FUND_SHORTFALL_FIELD,
  ORIGIN_CURRENCY,
  ORIGINS_FIELD,
  POOL_ID,
  POOL_STATUS_ENUM,
  PRIZE_BONUS_INDIVIDUAL_FIELD,
  PRIZE_BONUS_TOTAL_FIELD,
  PRIZE_CASH_DISTRIBUTION_FIELD,
  PRIZE_DISTRIBUTION_FIELD,
  PRIZE_GUARANTEE_FIELD,
  PRIZE_JACKPOT_FIELD,
  VISIBLE_TO_UNREGISTER_FIELD
} from '../../constants';
import { BrandsOrOriginsListRequestAnswer, CreationFormDictType, DropdownElement } from '../../types';

const validateLangStringField = (
  isMandatory: boolean,
  lowerBound: number,
  upperBound: number,
  lang: string,
  fieldName: string,
  value: string,
  dataForRequest: CreationFormDictType
) => {
  if (!value) {
    dataForRequest[fieldName] = dataForRequest[fieldName].filter(langDict => langDict.lang !== lang);
  } else if (value.length < lowerBound || value.length > upperBound) {
    toast.error(
      `Pool's ${fieldName} in ${lang} must be less,` +
        `than ${upperBound} and more, than ${lowerBound} symbols`
    );
    return `${fieldName}_${lang}`;
  }
};

export const validateNumberField = (
  isMandatory: boolean,
  lowerBound: number,
  upperBound: number,
  fieldName: string,
  value: string,
  dataForRequest: CreationFormDictType
) => {
  if (!value) {
    if (isMandatory) {
      toast.error(`${fieldName} is a mandatory field`);
      return fieldName;
    } else {
      delete dataForRequest[fieldName];
      return;
    }
  } else if (isNaN(Number(value))) {
    toast.error(`${fieldName} must be a number`);
    return fieldName;
  } else if (Number(value) < lowerBound || Number(value) > upperBound) {
    toast.error(`${fieldName} must be a number more than ${lowerBound}`);
    return fieldName;
  }
  if (fieldName !== PRIZE_DISTRIBUTION_FIELD) {
    dataForRequest[fieldName] = Number(value);
  }
};

export const validateField = (
  isCreate: boolean,
  formState: CreationFormDictType,
  name: string,
  value: string | Array<string> | boolean,
  dataForRequest: CreationFormDictType,
  lang?: string,
  status?: string
) => {
  if (name === BRAND_FIELD) {
    if (!value) {
      toast.error(`${name} is mandatory field`);
      return name;
    }
  }
  if (name === ORIGINS_FIELD) {
    if (!value || (typeof value === 'object' && !value.length)) {
      toast.error(`${name} is mandatory field`);
      return name;
    }
  }
  if (name === NAMES_FIELD) {
    if (typeof value === 'string') {
      return validateLangStringField(false, 3, 50, lang, name, value, dataForRequest);
    }
  }
  if (name === DESCRIPTIONS_FIELD) {
    if (typeof value === 'string') {
      return validateLangStringField(false, 3, 1500, lang, name, value, dataForRequest);
    }
  }
  if (name === OPEN_DATE_FIELD) {
    if (!value) {
      toast.error('Starting time is mandatory field');
      return name;
    } else if (
      [ POOL_STATUS_ENUM.AVAILABLE, POOL_STATUS_ENUM.OPEN, POOL_STATUS_ENUM.LIVE ]
        .map(s => String(s))
        .includes(status) &&
      !isCreate
    ) {
      // @ts-ignore
      const startDate = new Date(value);
      const currentDate = new Date();
      if (currentDate > startDate) {
        toast.error('Starting time must be in future');
        return name;
      }
    }
  }
  if (name === MAX_ENTRIES_PER_USER_FIELD) {
    if (typeof value === 'string') {
      const lowerBound = isCreate ? 1 : 0;
      const upperBound = Math.pow(2, 31);
      return validateNumberField(false, lowerBound, upperBound, name, value, dataForRequest);
    }
  }
  if (name === MAX_ENTRIES_PER_POOL_FIELD) {
    if (Number(formState[MAX_ENTRIES_PER_USER_FIELD]) >= Number(value) && value && value !== '0') {
      toast.error(
        'Max number of entries for the pool must be more than max number of entries for per player'
      );
      return name;
    }
    if (typeof value === 'string') {
      const lowerBound = isCreate ? 2 : 0;
      const upperBound = Math.pow(2, 31);
      return validateNumberField(false, lowerBound, upperBound, name, value, dataForRequest);
    }
  }
  if (name === PRIZE_DISTRIBUTION_FIELD && typeof value === 'object') {
    let sum = 0;
    for (const item of value) {
      const errorField = validateNumberField(false, 1, 100, name, item, dataForRequest);
      if (errorField) {
        return errorField;
      } else {
        sum += Number(item);
      }
    }
    if (sum !== 100) {
      toast.error(
        'At least one place for prize distribution must be a number' +
          'and distribution of percent between all places must be in summary = 100%'
      );
      return name;
    }
  }
  if (name === ENTRY_BET_FIELD) {
    if (typeof value === 'string') {
      const upperBound = Math.pow(2, 31);
      return validateNumberField(true, 0, upperBound, name, value, dataForRequest);
    }
  }
  if (name === COMMISSION_FIELD) {
    if (typeof value === 'string') {
      return validateNumberField(true, 0, 100, name, value, dataForRequest);
    }
  }
  if (name === INSURANCE_COMMISSION_FIELD) {
    if (typeof value === 'string') {
      return validateNumberField(false, 0, 999, name, value, dataForRequest);
    }
  }
  if (name === PRIZE_BONUS_TOTAL_FIELD) {
    if (
      value < dataForRequest.prizeBonusIndividual &&
      !isNaN(parseFloat(dataForRequest.prizeBonusTotal))
    ) {
      toast.error('Total bonus must be equal or more than value in the Individual bonus');
      return name;
    }
    if (typeof value === 'string') {
      return validateNumberField(false, 0, 999999, name, value, dataForRequest);
    }
  }
  if ([ PRIZE_JACKPOT_FIELD, PRIZE_BONUS_INDIVIDUAL_FIELD, PRIZE_BONUS_TOTAL_FIELD ].includes(name)) {
    if (typeof value === 'string') {
      const upperBound = Math.pow(2, 31);
      return validateNumberField(false, 0, upperBound, name, value, dataForRequest);
    }
  }
  if (name === PRIZE_GUARANTEE_FIELD) {
    if (!!value && value !== '0' && Number(formState[ENTRY_BET_FIELD]) > Number(value)) {
      toast.error(`${name} must be more then Entry bet`);
      return name;
    } else if (typeof value === 'string') {
      return validateNumberField(
        formState[OPERATOR_FUND_SHORTFALL_FIELD],
        0,
        999999,
        name,
        value,
        dataForRequest
      );
    }
  }
};

export const originsOrBrandsOnSuccess = (
  data: BrandsOrOriginsListRequestAnswer,
  setter: (elementsList: Array<DropdownElement>) => void
) => {
  const elementsList = data.result;
  setter(
    elementsList.map(answerDict => ({
      key: answerDict.id,
      text: answerDict.description,
      value: answerDict.id,
      currency: answerDict.currencyCode || null,
    }))
  );
};

export function getInitialState(langsList, poolInfo) {
  const {
    id,
    brandId,
    originIds,
    names,
    descriptions,
    prizeJackpot,
    openDate,
    maxEntriesPerUser,
    maxEntriesPerPool,
    prizeDistribution,
    entryBet,
    commission,
    prizeBonusIndividual,
    prizeBonusTotal,
    insuranceCommission,
    operatorFundShortfall,
    isVisibleToUnregistered,
    isDoubleScore,
    prizeFundGuarantee,
    currencyCode,
  } = poolInfo;
  const descriptionLangs = getLanguagesArray(langsList, descriptions);
  const namesLangs = getLanguagesArray(langsList, names);

  return {
    [POOL_ID]: id,
    [BRAND_FIELD]: brandId,
    [ORIGINS_FIELD]: [ ...originIds ],
    [NAMES_FIELD]: namesLangs,
    [DESCRIPTIONS_FIELD]: descriptionLangs,
    [PRIZE_JACKPOT_FIELD]: String(prizeJackpot),
    [OPEN_DATE_FIELD]: openDate,
    [MAX_ENTRIES_PER_USER_FIELD]: Number(maxEntriesPerUser) === 0 ? '' : String(maxEntriesPerUser),
    [MAX_ENTRIES_PER_POOL_FIELD]: Number(maxEntriesPerPool) === 0 ? '' : String(maxEntriesPerPool),
    [NUMBER_OF_PLACES]: prizeDistribution.length,
    [PRIZE_DISTRIBUTION_FIELD]: prizeDistribution.map(e => String(e)),
    [PRIZE_CASH_DISTRIBUTION_FIELD]: prizeDistribution.map(() => ''),
    [ENTRY_BET_FIELD]: String(entryBet),
    [COMMISSION_FIELD]: String(commission),
    [PRIZE_BONUS_INDIVIDUAL_FIELD]: String(prizeBonusIndividual),
    [PRIZE_BONUS_TOTAL_FIELD]: String(prizeBonusTotal),
    [INSURANCE_COMMISSION_FIELD]: String(insuranceCommission),
    [OPERATOR_FUND_SHORTFALL_FIELD]: operatorFundShortfall,
    [VISIBLE_TO_UNREGISTER_FIELD]: isVisibleToUnregistered,
    [DOUBLE_SCORE_FIELD]: isDoubleScore,
    [PRIZE_GUARANTEE_FIELD]: String(prizeFundGuarantee),
    [ORIGIN_CURRENCY]: currencyCode || '',
  };
}

function getLanguagesArray(langsList, array) {
  const result = langsList.map(langDict => ({ lang: langDict.lang, text: '' }));

  result.forEach((el, index) => {
    array.forEach(elem => {
      if (el.lang === elem.lang) {
        result[index] = { ...elem };
      }
    });
  });

  return result;
}
