import React from 'react';

import { Dropdown } from 'semantic-ui-react';

export const DropdownMultiply = ({
  options,
  value,
  onChange,
}: {
  options: Array<{
    key: string;
    text: string;
    value: string;
  }>;
  value: Array<string>;
  onChange: (string) => void;
}) => (
  <Dropdown
    placeholder="Select origins"
    fluid
    selection
    multiple
    options={options}
    value={value}
    onChange={onChange}
  />
);
