import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { Layout } from './components/Layout';
import { CreatePoolPage } from './pages/CreatePool/CreatePoolPage';
import { EditPoolPage } from './pages/EditPool/EditPoolPage';
import { IpoolsTablePage } from './pages/Home/IpoolsTablePage';
import { EntriesTab } from './pages/PoolPage/EntriesTab/EntriesTab';
import { PoolPage } from './pages/PoolPage/PoolPage';
import { PoolQuestionsTab } from './pages/PoolPage/QuestionsTab/PoolQuestionsTab';

import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';

export const App = ({ username, logout }: { username: string; logout: () => void }) => (
  <Layout username={username} logout={logout}>
    <Routes>
      <Route path="/" element={<IpoolsTablePage />} />
      <Route path="/createPool" element={<CreatePoolPage />} />
      <Route path="/ipools" element={<IpoolsTablePage />} />
      <Route path="/ipools/:poolId" element={<PoolPage />}>
        <Route path="" element={<PoolQuestionsTab />} />
        <Route path="entries" element={<EntriesTab />} />
      </Route>
      <Route path="/ipools/:poolId/editPool" element={<EditPoolPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </Layout>
);
