import React from 'react';

import axios from 'axios';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'semantic-ui-react';

import {
  ANSWER_RESULT_OPTIONS,
  AnswerResultsEnum,
  ANSWERS,
  ANSWERS_DESCRIPTION,
  ANSWERS_SCORES,
  API_ANSWER_REMOVE
} from '../../../../constants';
import { PoolAnswerResult, PoolQuestionStateType, ReduxStateType } from '../../../../types';
import { switchDescription, updatePoolInfo } from '../../../../utils';

export const AnswerRow = ({
  answer,
  questionState,
  setQuestionState,
  isEditable,
  isSetResultEditable,
  isVoidEditable,
  errorsState,
  index,
}: {
  answer: {
    id: string;
    outcomeId: string;
    scores: string | number;
    result: PoolAnswerResult;
    descriptions: Array<{ lang: string; text: string }>;
  };
  questionState: PoolQuestionStateType;
  setQuestionState: (questionState: (prevState) => PoolQuestionStateType) => void;
  isEditable: boolean;
  isSetResultEditable: boolean;
  isVoidEditable: boolean;
  errorsState: Array<string>;
  index: number;
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { actualLanguage, actualId: poolId } = state.variables;
  const { isPoolWithMultiplyChoice } = state.poolInfo;

  const inputOnChange = React.useCallback(
    (event, data) => {
      setQuestionState(prevState => {
        const updateAnswers = prevState.answers.map(answerFromState => {
          if (answerFromState.id !== answer.id) return answerFromState;

          if (data.id === ANSWERS_SCORES) {
            const value = !isNaN(Number(data.value)) ? Number(data.value) : answerFromState.scores;
            return { ...answerFromState, scores: value };
          }

          return {
            ...answerFromState,
            descriptions: answerFromState.descriptions.map(el =>
              el.lang === actualLanguage ? { lang: actualLanguage, text: data.value } : el
            ),
          };
        });

        return {
          ...prevState,
          answers: updateAnswers,
        };
      });
    },
    [ setQuestionState, answer.id, actualLanguage ]
  );

  const resultSelectorOnClick = React.useCallback(
    result => {
      setQuestionState(prevState => {
        const updatedAnswers = prevState.answers.map(answerFromState => {
          if (answerFromState.id === answer.id) {
            return {
              ...answerFromState,
              result,
            };
          } else {
            return answerFromState;
          }
        });
        return {
          ...prevState,
          answers: updatedAnswers,
        };
      });
    },
    [ setQuestionState, answer ]
  );

  const deleteAnswer = React.useCallback(() => {
    axios.post(API_ANSWER_REMOVE, { id: answer.id }).then(() => {
      updatePoolInfo({ poolId, dispatch });
      setQuestionState(prevState => {
        const updateAnswers = [ ...prevState.answers.filter(a => a.id !== answer.id) ];
        return { ...prevState, answers: updateAnswers };
      });
    });
  }, [ answer.id, dispatch, poolId, setQuestionState ]);

  return (
    <div className="question-answer-row">
      <div className="question-answer-row__inputs-wrapper">
        {isPoolWithMultiplyChoice && (
          <Button
            icon="minus"
            basic
            disabled={!isEditable || questionState.answers.length <= 2}
            onClick={deleteAnswer}
          />
        )}
        <Input
          className="question-answer-row__description"
          size="small"
          value={switchDescription(answer.descriptions, actualLanguage)}
          disabled={!isEditable}
          id={ANSWERS_DESCRIPTION}
          onChange={inputOnChange}
          error={errorsState.includes(`${ANSWERS}_${index}_description`)}
        />
        <Input
          className="question-answer-row__score"
          size="small"
          value={answer.scores}
          id={ANSWERS_SCORES}
          disabled={!isEditable}
          onChange={inputOnChange}
          error={errorsState.includes(`${ANSWERS}_${index}_score`)}
        />
      </div>
      <Button.Group size="mini" basic>
        {ANSWER_RESULT_OPTIONS.map(([ id, label ]) => (
          <Button
            key={id}
            size="mini"
            basic={answer.result !== id}
            className={cx('question-answer-row__change-button', {
              'question-answer-row__change-button_active': answer.result === id,
            })}
            disabled={id === AnswerResultsEnum.VOID ? !isVoidEditable : !isSetResultEditable}
            onClick={() => resultSelectorOnClick(id)}
          >
            {label}
          </Button>
        ))}
      </Button.Group>
    </div>
  );
};
