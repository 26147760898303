import React from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { AxiosRequest } from '../../components/AxiosRequest';
import { EditForm } from '../../components/edit-pool-form/EditForm';
import { useAxiosRequest } from '../../components/use-axios-request/use-axios-request.es';
import { API_FIND_BY_ID, API_LANGUAGE_LIST } from '../../constants';
import { changePoolInfoAction } from '../../store/reducers/poolInfo';
import { LanguagesListType } from '../../types';

export const EditPoolPage = () => (
  <AxiosRequest
    config={{
      method: 'GET',
      url: API_LANGUAGE_LIST,
    }}
  >
    {({ data }) => {
      const langsList = data.result;
      return <EditPoolPageComponent langsList={langsList} />;
    }}
  </AxiosRequest>
);

const EditPoolPageComponent = ({ langsList }: { langsList: LanguagesListType }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { poolId } = params;

  const config = React.useMemo(
    () => ({
      url: API_FIND_BY_ID,
      params: { id: poolId },
    }),
    [ poolId ]
  );

  const dispatchOnSuccess = data => {
    dispatch(changePoolInfoAction(data.result));
  };

  const { isFetching, data } = useAxiosRequest(config, {
    onSuccess: dispatchOnSuccess,
    onError: error => toast.error(error.message),
  });

  if (isFetching || !data) return <Loader />;

  return <EditForm isCreate={false} langsList={langsList} />;
};
