import React from 'react';

import axios, { AxiosRequestConfig } from 'axios';
import { Dropdown } from 'semantic-ui-react';

export const DropdownWithFetch = ({
  value,
  onChange,
  config,
  placeholder,
  defaultOptions = [],
}: {
  value: string;
  onChange: (e: any, { value }: { value: any }) => void;
  config: AxiosRequestConfig;
  placeholder?: string;
  defaultOptions?: Array<{
    key: string;
    value: string;
    text: string;
  }>;
}) => {
  const [ options, setOptions ] = React.useState(defaultOptions);
  const [ loading, setLoading ] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);

    try {
      axios(config)
        .then(res => res.data)
        .then(data => {
          setOptions(prev => [
            ...prev,
            ...data.result.map(o => ({
              key: o.id,
              value: o.id,
              text: o.description,
            }))
          ]);
        })
        .finally(() => setLoading(false));
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dropdown
      selection
      options={options}
      value={value}
      onChange={onChange}
      loading={loading}
      placeholder={placeholder}
    />
  );
};
