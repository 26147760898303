import React from 'react';

import { Tab } from 'semantic-ui-react';

import { CreationFormField } from './CreationFormField';
import { DESCRIPTIONS_FIELD, INPUT, NAMES_FIELD, TEXT_EDITOR } from '../../constants';
import { CreationFormDictType, LanguagesListType } from '../../types';

export const LanguageTabs = ({
  formState,
  setFormState,
  setBackToPool,
  errorFieldsState,
  languagesList,
}: {
  formState: CreationFormDictType;
  setFormState: (any) => void;
  setBackToPool: (boolean) => void;
  errorFieldsState: Array<string>;
  languagesList: LanguagesListType;
}) => {
  const [ activeIndex, setActiveIndex ] = React.useState<string | number>(() => {
    const index = formState[DESCRIPTIONS_FIELD].findIndex(value => value.text);
    if (index === -1) return 0;
    return index;
  });

  const handleChangeForLangValues = React.useCallback(
    (e, { name, value }) => {
      const [ fieldName, lang ] = name.split('_');
      const langFieldValues = formState[fieldName];
      const updatedLangFieldValues = langFieldValues.map(langDict =>
        langDict.lang === lang ? { lang: lang, text: value } : langDict
      );
      setFormState({
        ...formState,
        [fieldName]: updatedLangFieldValues,
      });
      setBackToPool(false);
    },
    [ formState, setFormState, setBackToPool ]
  );

  const renderFunction = langDict => {
    const namesFieldName = `${NAMES_FIELD}_${langDict.lang}`;
    const descFieldName = `${DESCRIPTIONS_FIELD}_${langDict.lang}`;

    const initialValue = formState[DESCRIPTIONS_FIELD].find(el => el.lang === langDict.lang)
      ? formState[DESCRIPTIONS_FIELD].find(el => el.lang === langDict.lang).text
      : '';

    return (
      <Tab.Pane className={'lang-pane'}>
        <CreationFormField
          label="Name pool"
          type={INPUT}
          inputWidth={13}
          labelWidth={3}
          name={namesFieldName}
          handleChange={handleChangeForLangValues}
          error={errorFieldsState.includes(NAMES_FIELD)}
          value={formState[NAMES_FIELD].find(el => el.lang === langDict.lang).text}
        />
        <CreationFormField
          label="Description"
          inputWidth={13}
          labelWidth={3}
          type={TEXT_EDITOR}
          name={descFieldName}
          error={errorFieldsState.includes(DESCRIPTIONS_FIELD)}
          value={initialValue}
          formState={formState}
          setFormState={setFormState}
          setBackToPool={setBackToPool}
        />
      </Tab.Pane>
    );
  };

  const panes = languagesList.map(langDict => ({
    menuItem: langDict.lang,
    render: () => renderFunction(langDict),
  }));

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      activeIndex={activeIndex}
      onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
      menuPosition={'right'}
      panes={panes}
      className="edit-form__language-tabs"
    />
  );
};
