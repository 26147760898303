import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Header, Table } from 'semantic-ui-react';

import { FeedQuestionTableRow } from './FeedQuestionTableRow';
import { FeedQuestionSkeleton } from '../../../../components/FeedQuestionSkeleton';
import { useAxiosRequest } from '../../../../components/use-axios-request/use-axios-request.es';
import { API_EVENTS_FIND } from '../../../../constants';
import { changeLimitSearchAction } from '../../../../store/reducers/searchStateFeedQuestions';
import { FeedQuestionsSearchState, ReduxStateType } from '../../../../types';
import { getStartDate } from '../../../../utils';

export const FeedQuestionTable = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { limit, searchLine, startDateFrom, startDateTo, sportId, regionId, leagueId } =
    state.feedQuestionSearch;

  const [ questionsList, setQuestionsList ] = React.useState(null);

  const config = React.useMemo(() => {
    const params: FeedQuestionsSearchState = {
      startDateFrom: getStartDate(),
      limit: 10,
    };

    if (limit) {
      params.limit = limit;
    }

    if (searchLine && searchLine.length > 3) {
      params.searchLine = searchLine;
    }

    if (startDateFrom) {
      params.startDateFrom = startDateFrom;
    }

    if (startDateTo) {
      params.startDateTo = startDateTo;
    }

    if (sportId && sportId !== '') {
      params.sportId = sportId;
    }

    if (regionId) {
      params.regionId = regionId;
    }

    if (leagueId) {
      params.leagueId = leagueId;
    }

    return {
      url: API_EVENTS_FIND,
      params: { ...params },
    };
  }, [ limit, searchLine, startDateFrom, startDateTo, sportId, regionId, leagueId ]);

  const { data, isFetching, error } = useAxiosRequest(config, {
    onSuccess: data => setQuestionsList(data?.result?.result),
    onError: error => {
      toast.error(error);
      setQuestionsList(null);
    },
  });

  const [ sortingState, setSortingState ] = React.useState<{
    column: 'date' | null | void;
    direction: 'ascending' | 'descending';
  }>({
    column: null,
    direction: 'ascending',
  });

  const sortBy = React.useCallback(
    column => {
      setSortingState(state => ({
        column,
        direction: state.direction === 'ascending' ? 'descending' : 'ascending',
      }));
    },
    [ setSortingState ]
  );

  const sortByDate = React.useCallback(() => sortBy('date'), [ sortBy ]);

  const sortedEntriesList = React.useMemo(() => {
    if (sortingState.column === 'date' && !!questionsList) {
      const sortingQuestionsList = questionsList;
      sortingQuestionsList.sort((a, b) =>
        sortingState.direction === 'ascending'
          ? Number(new Date(a.startDate)) - Number(new Date(b.startDate))
          : Number(new Date(b.startDate)) - Number(new Date(a.startDate))
      );
      return sortingQuestionsList;
    } else return questionsList;
  }, [ questionsList, sortingState ]);

  const getMoreQuestions = () => {
    if (isFetching) return;
    const newLimit = limit + 10;
    dispatch(changeLimitSearchAction(newLimit));
  };

  const isDisableMoreEvents =
    (!!sortedEntriesList && !!data.result && sortedEntriesList.length === data.result.totalCount) ||
    !sortedEntriesList;

  return (
    <Table sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={sortingState.column === 'date' ? sortingState.direction : null}
            onClick={sortByDate}
            width={4}
          >
            Start date
          </Table.HeaderCell>
          <Table.HeaderCell>Event Info</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body className="feed-question-table-body">
        {isFetching && !questionsList && <FeedQuestionSkeleton />}
        {!questionsList && !error && (
          <Table.Row>
            <Table.Cell colSpan={2}>
              <Header as="h3" textAlign="center">
                No events
              </Header>
            </Table.Cell>
          </Table.Row>
        )}
        {error && (
          <Table.Row>
            <Table.Cell colSpan={2}>
              <Header as="h3" textAlign="center">
                Error {error.code}
              </Header>
            </Table.Cell>
          </Table.Row>
        )}
        {questionsList &&
          sortedEntriesList.map(question => (
            <FeedQuestionTableRow key={question.id} question={question} />
          ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={2}>
            <Button
              className="feed-question__button_get-more"
              size="medium"
              onClick={getMoreQuestions}
              disabled={!isFetching && isDisableMoreEvents}
              loading={isFetching}
            >
              {isFetching ? 'Loading' : 'Get more events'}
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};
