import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Dropdown, Grid, Header } from 'semantic-ui-react';

import { useAxiosRequest } from '../../components/use-axios-request/use-axios-request.es';
import {
  ANSWER_RESULT_UNKNOWN_OPTION,
  API_POOL_UPDATE,
  IPOOLS_DATE_FORMAT,
  IPOOLS_PATH,
  POOL_STATUS_ENUM,
  STATUSES_FOR_CHANGE
} from '../../constants';
import { changePoolStatusAction } from '../../store/reducers/poolInfo';
import { ReduxStateType } from '../../types';
import { formattingDate, updatePoolInfo } from '../../utils';

export const PoolInfoSegment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state: ReduxStateType) => state);
  const { actualLanguage, actualId: poolId } = state.variables;
  const { entries } = state.poolEntries;

  const {
    questions,
    openDate,
    liveDate,
    lastUpdateDate,
    createDate,
    names,
    descriptions,
    createdBy,
    updatedBy,
    status,
  } = state.poolInfo;

  const isAllQuestionsResulted = React.useCallback(
    () =>
      questions.every(question =>
        question.answers.every(answer => answer.result !== ANSWER_RESULT_UNKNOWN_OPTION)
      ),
    [ questions ]
  );

  const getStatusSelections = React.useCallback(() => {
    const dropdownStatuses = STATUSES_FOR_CHANGE[status];
    if (
      status === POOL_STATUS_ENUM.COMPLETED &&
      isAllQuestionsResulted() &&
      !dropdownStatuses.includes(POOL_STATUS_ENUM.SETTLED)
    ) {
      dropdownStatuses.push(POOL_STATUS_ENUM.SETTLED);
    }
    return STATUSES_FOR_CHANGE[status].map(status => ({
      key: POOL_STATUS_ENUM[status],
      value: POOL_STATUS_ENUM[status],
      text: status,
    }));
  }, [ status, isAllQuestionsResulted ]);

  const { update: updateStatus } = useAxiosRequest(null, {
    onSuccess: () => {
      updatePoolInfo({ poolId, dispatch });
      toast.success('Pool status was successfully changed');
    },
    onError: data => {
      toast.error(data.message);
    },
  });

  const statusOnChange = React.useCallback(
    (event, { value }) => {
      if (questions.length === 0 && value === POOL_STATUS_ENUM.AVAILABLE) {
        toast.error('Please add at least one question');
        return;
      }

      if (status === POOL_STATUS_ENUM.OPEN && value === POOL_STATUS_ENUM.DRAFT && entries.length !== 0) {
        toast.error('Pool should not contain entries');
        return;
      }

      const newStatus =
        value === POOL_STATUS_ENUM.SETTLED
          ? POOL_STATUS_ENUM.SETTLE_IN_PROGRESS
          : POOL_STATUS_ENUM[value];

      dispatch(changePoolStatusAction(newStatus));

      updateStatus({
        method: 'PUT',
        url: API_POOL_UPDATE,
        data: {
          id: poolId,
          status: value,
        },
      });
    },
    [ dispatch, entries.length, poolId, questions.length, status, updateStatus ]
  );

  const openLocalDate = formattingDate(openDate, IPOOLS_DATE_FORMAT);
  const liveLocalDate = formattingDate(liveDate, IPOOLS_DATE_FORMAT);
  const createdLocalDate = formattingDate(createDate, IPOOLS_DATE_FORMAT);
  const settleLocalDate = formattingDate(
    status === POOL_STATUS_ENUM.SETTLED && lastUpdateDate,
    IPOOLS_DATE_FORMAT
  );

  const filterNames = names.filter(el => el.lang === actualLanguage);
  const actualName = !filterNames.length ? '' : filterNames[0].text;

  const filterDescriptions = descriptions.filter(el => el.lang === actualLanguage);
  const actualDescription = !filterDescriptions.length ? '' : filterDescriptions[0].text;

  const disableEditPoolButton = ![
    POOL_STATUS_ENUM.AVAILABLE,
    POOL_STATUS_ENUM.OPEN,
    POOL_STATUS_ENUM.LIVE,
    POOL_STATUS_ENUM.DRAFT
  ]
    .map(s => String(s))
    .includes(status);

  function editPoolButtonOnClick() {
    navigate(`${IPOOLS_PATH}/${poolId}/editPool`);
  }

  return (
    <Grid>
      <Grid.Row columns={2} className="pool-info__header">
        <Grid.Column width={15} className="pool-info__header-cell">
          <Header as={'h1'} className="pool-name">
            {actualName}
          </Header>
          <Dropdown
            className="button icon status-dropdown"
            floating
            text={status.replace(/_/g, ' ')}
            disabled={status === POOL_STATUS_ENUM.VOIDED}
          >
            <Dropdown.Menu>
              {getStatusSelections().map(el => (
                <Dropdown.Item key={el.key} text={el.text} value={el.value} onClick={statusOnChange} />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Grid.Column>
        <Grid.Column width={1} className="pool-info__button_update">
          <Button icon="pencil" disabled={disableEditPoolButton} onClick={editPoolButtonOnClick} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column width={10}>
          <Grid.Row>
            <p
              className="pool-info__description"
              dangerouslySetInnerHTML={{ __html: actualDescription }}
            ></p>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={6}>
          <Grid>
            <Grid.Row columns={2} className="dates-top-row">
              <Grid.Column width={8}>
                <Grid.Row>
                  <Header as="h6">Created</Header>
                </Grid.Row>
                <Grid.Row>{createdBy ? createdBy : 'Creator'}</Grid.Row>
                <Grid.Row>{createdLocalDate}</Grid.Row>
              </Grid.Column>
              <Grid.Column width={8}>
                <Grid.Row>
                  <Header as="h6">Open time</Header>
                </Grid.Row>
                <Grid.Row>
                  <p className={'open-time'}>{openLocalDate}</p>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className="dates-bottom-row">
              <Grid.Column width={8}>
                <Grid.Row>
                  <Header as="h6">Settled at</Header>
                </Grid.Row>
                <Grid.Row>{status === POOL_STATUS_ENUM.SETTLED && updatedBy}</Grid.Row>
                <Grid.Row>{settleLocalDate}</Grid.Row>
              </Grid.Column>
              <Grid.Column width={8}>
                <Grid.Row>
                  <Header as="h6">Betting end time</Header>
                </Grid.Row>
                <Grid.Row>
                  <p className="end-time">{liveLocalDate}</p>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
