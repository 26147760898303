import React from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

import { useAxiosRequest } from '../../../../components/use-axios-request/use-axios-request.es';
import { API_QUESTION_ADD } from '../../../../constants';
import { FeedQuestionMarketType, ReduxStateType } from '../../../../types';

export const FeedQuestionMarket = ({ market }: { market: FeedQuestionMarketType }) => {
  const state = useSelector((state: ReduxStateType) => state);

  const { actualId: poolId } = state.variables;

  const { update, isFetching: addingQuestion } = useAxiosRequest(null, {
    onSuccess: () => {
      toast.success('Market added successfully');
    },
    onError: data => toast.error(data.message),
  });

  const addFeedQuestion = React.useCallback(
    marketId => {
      update({
        method: 'POST',
        url: API_QUESTION_ADD,
        data: {
          poolId: poolId,
          marketId: marketId,
        },
      });
    },
    [ poolId, update ]
  );

  return (
    <div className="feed-question__market-wrapper" key={market.id}>
      <div className="feed-question__market feed-question-market">
        <span className="feed-question-market__header">{market.description}</span>
        <br />
        {market.outcomes.length > 0 &&
          market.outcomes.map((outcome, index) => (
            <p className="feed-question-market__outcomes" key={outcome.id}>
              <span>{outcome.description}</span>
              <span> ({outcome.priceValue})</span>
              {index !== market.outcomes.length - 1 && <span> - </span>}
            </p>
          ))}
      </div>
      <Button
        className="feed-question-market__button"
        onClick={() => addFeedQuestion(market.id)}
        size="small"
        loading={addingQuestion}
      >
        Select market
      </Button>
    </div>
  );
};
