import React from 'react';

import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import deepEqual from 'fast-deep-equal';
import qs from 'qs';
import { Loader } from 'semantic-ui-react';
import { $Shape } from 'utility-types';

import { ErrorBoundary } from './ErrorBoundary';

const FETCHING = 'fetching';
const FETCHED = 'fetched';
const POLLING = 'polling';
const NONE = 'none';

const Cache = new Map();

declare global {
  interface Window {
    __axiosRequestCache;
  }
}

if (process.env.NODE_ENV === 'development') {
  window.__axiosRequestCache = Cache;
}

export const Loading = () => (
    <div style={{ height: '90vh', display: 'flex', alignItems: 'center' }}>
      <Loader active inline="centered" size="large" />
    </div>
  );

type DerivedStateFromProps = {
  config?: AxiosRequestConfig | string | null;
  reloadTicker?: any;
};

enum StatusType {
  fetching = 'fetching',
  fetched = 'fetched',
  polling = 'polling',
  none = 'none',
}

type State = DerivedStateFromProps & {
  canUseCache?: boolean;
  data?: any;
  error?: Error | null | undefined;
  status?: keyof typeof StatusType;
  configHasChanged?: boolean;
};

type Props = DerivedStateFromProps & {
  children?: (
    arg: State & {
      isFetching: boolean;
      isFetched: boolean;
    }
  ) => React.ReactNode;
  pollInterval?: number;
  noLoading?: boolean;
  cache?: boolean | 'reload';
  noError?: boolean;
  Loading?: React.ComponentType;
};

const getCacheKeyFromConfig = (config: AxiosRequestConfig | string | null) => {
  if (config == null) {
    return null;
  }
  return typeof config === 'string'
    ? config
    : qs.parse(config.url, config.params);
};

class AxiosRequestComponent extends React.Component<Props, State> {
  source: CancelTokenSource | null;
  pollTimer: ReturnType<typeof setTimeout>;

  static defaultProps = {
    pollInterval: 0,
    Loading: Loading,
  };

  static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): $Shape<State> | null {
    const tickerChanged = nextProps.reloadTicker !== prevState.reloadTicker;
    if (tickerChanged || !deepEqual(nextProps.config, prevState.config)) {
      const canUseCache = !!nextProps.cache && nextProps.config != null;
      const wasCached =
        canUseCache && Cache.has(getCacheKeyFromConfig(nextProps.config));
      const data = wasCached
        ? Cache.get(getCacheKeyFromConfig(nextProps.config))
        : null;

      const status = wasCached
        ? nextProps.cache === 'reload' || tickerChanged
          ? POLLING
          : FETCHED
        : nextProps.config
        ? FETCHING
        : NONE;

      return {
        config: nextProps.config,
        canUseCache,
        data,
        error: null,
        status,
        reloadTicker: nextProps.reloadTicker,
      };
    }

    return null;
  }

  state = {
    config: null,
    canUseCache: false,
    data: null,
    error: null,
    status: StatusType.none,
    configHasChanged: false,
    reloadTicker: this.props.reloadTicker,
  };

  componentDidMount() {
    const { status } = this.state;
    if (status === FETCHING || status === POLLING) {
      this.request();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      (prevState.status !== FETCHING && this.state.status === FETCHING) ||
      (prevState.status !== POLLING && this.state.status === POLLING)
    ) {
      this.request();
    }
  }

  async request() {
    this.clearSubscriptions();

    const { config, canUseCache } = this.state;
    const { pollInterval } = this.props;
    if (!config) {
      return;
    }

    this.source = axios.CancelToken.source();

    const nextState: State = {};

    try {
      const axiosConfig =
        typeof config === 'string'
          ? {
              url: config,
              method: 'GET',
              cancelToken: this.source.token,
            }
          : {
              cancelToken: this.source.token,
              ...config,
            };

      const { data } = await axios(axiosConfig);

      if (canUseCache) {
        Cache.set(getCacheKeyFromConfig(config), data);
      }

      nextState.data = data;
      nextState.error = null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      if (axios.isCancel(error)) {
        return;
      }

      nextState.error = error;
    }

    this.setState(() => {
      nextState.status = FETCHED;
      return nextState;
    });

    if (pollInterval > 0) {
      this.pollTimer = setTimeout(() => {
        this.setState({
          status: POLLING,
        });
      }, pollInterval);
    }
  }

  clearSubscriptions() {
    if (this.source) {
      this.source.cancel();
      this.source = null;
    }

    if (this.pollTimer) {
      clearTimeout(this.pollTimer);
    }
  }

  componentWillUnmount() {
    this.clearSubscriptions();
  }

  render() {
    const { status, error } = this.state;
    const { noLoading, noError, Loading } = this.props;

    if (status === FETCHING && !noLoading) {
      return <Loading />;
    }

    if (error && !noError) {
      throw error;
    }

    return this.props.children({
      ...this.state,
      isFetching: status === FETCHING,
      isFetched: status === FETCHED,
    });
  }
}

export const AxiosRequest = (props: Props) => (
  <ErrorBoundary>
    <AxiosRequestComponent {...props} />
  </ErrorBoundary>
);
