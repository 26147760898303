import React from 'react';

import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

import { COLOR_PURPLE } from '../constants';

const styles = {
  menu: {
    backgroundColor: COLOR_PURPLE,
  },
  menuDropdown: {
    backgroundColor: COLOR_PURPLE,
  },
};

export const HeaderMenu = React.memo(
({ username, logout }: { username: string; logout: () => void }) => (
  <Menu fixed="top" inverted style={styles.menu}>
    <Menu.Item as={Link} to="/ipools">
      iPools
    </Menu.Item>

    <Menu.Menu position="right">
      <Dropdown item text={`User: ${username}`}>
        <Dropdown.Menu style={styles.menuDropdown}>
          <Dropdown.Item onClick={logout}>
            <span
              style={{
                color: 'white',
              }}
            >
              Logout
            </span>
          </Dropdown.Item>
          <Dropdown.Item disabled>
            <span
              style={{
                color: 'white',
              }}
            >
              Version {process.env.REACT_APP_BUILD}
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Menu>
  </Menu>
));
