import React from 'react';

import { EditorState, RichUtils } from 'draft-js';
import { Button, Icon } from 'semantic-ui-react';

const BLOCK_TYPES = [
  { label: 'Normal', style: 'unstyled' },
  { label: 'Highlight', style: 'blockquote' },
  { label: '', style: 'unordered-list-item', icon: 'list ul' },
  { label: '', style: 'ordered-list-item', icon: 'list ol' }
];

const INLINE_STYLES = [
  { label: '', style: 'BOLD', icon: 'bold' },
  { label: '', style: 'ITALIC', icon: 'italic' },
  { label: '', style: 'UNDERLINE', icon: 'underline' }
];

export const Toolbar = ({
  editorState,
  onInlineClick,
  onBlockClick,
}: {
  editorState: EditorState;
  onInlineClick: (string) => void;
  onBlockClick: (string) => void;
}) => {
  const StyleButton = props => {
    const onClickButton = e => {
      e.preventDefault();
      props.onToggle(props.style);
    };

    return (
      <Button active={props.active} icon onMouseDown={onClickButton}>
        {props.icon && <Icon name={props.icon} />}
        {props.label}
      </Button>
    );
  };

  const BlockStyleControls = props => (
    <>
      {BLOCK_TYPES.map(type => {
        const currentBlockType = RichUtils.getCurrentBlockType(editorState);
        return (
          <StyleButton
            key={type.style}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
            icon={type.icon}
            active={currentBlockType === type.style}
          />
        );
      })}
    </>
  );

  const InlineStyleControls = props => (
    <>
      {INLINE_STYLES.map(type => {
        const currentInlineStyle = editorState.getCurrentInlineStyle();
        return (
          <StyleButton
            key={type.style}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
            icon={type.icon}
            active={currentInlineStyle.has(type.style)}
          />
        );
      })}
    </>
  );

  return (
    <Button.Group basic>
      <InlineStyleControls onToggle={onInlineClick} />
      <BlockStyleControls onToggle={onBlockClick} />
    </Button.Group>
  );
};
