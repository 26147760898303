import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { DEFAULT_LANGUAGE_OBJECT } from '../constants';
import { changeLanguageAction } from '../store/reducers/poolVariables';
import { ReduxStateType } from '../types';
import { createAvailableLanguages } from '../utils';

export const LanguageSwitcher = React.memo(() => {
  const dispatch = useDispatch();
  const state = useSelector((state: ReduxStateType) => state);

  const { actualLanguage } = state.variables;
  const { names } = state.poolInfo;

  const [ availableLanguages, setAvailableLanguages ] = React.useState([ DEFAULT_LANGUAGE_OBJECT ]);

  React.useEffect(() => {
    const namesList = createAvailableLanguages(names);
    setAvailableLanguages(namesList);
  }, [ names ]);

  const languageSwitcher = React.useCallback(
    (e, { value }) => {
      dispatch(changeLanguageAction(value));
    },
    [ dispatch ]
  );

  return <Select item onChange={languageSwitcher} value={actualLanguage} options={availableLanguages} />;
});
