import { ReduxEntriesType } from '../../types';

const initialState: ReduxEntriesType = {
  entries: [
    {
      id: null,
      rank: null,
      origin: null,
      player: {
        playerId: null,
        playerName: null,
      },
      submitted: null,
      lastEdit: null,
      answers: [
        {
          questionId: null,
          questionDesc: null,
          answerId: null,
          answerDesc: null,
          score: null,
          isDouble: null,
        }
      ],
      score: {
        currentScore: null,
        totalScore: null,
        tieBreakScore: null,
      },
      isJackpot: null,
      isBonus: null,
      totalAmount: null,
      isVoided: null,
      playerCurrencyCode: null,
      playerTotalAmount: null,
    }
  ],
  activeRows: [],
};

const CHANGE_POOL_ENTRIES = 'CHANGE_POOL_ENTRIES';
const DEFAULT_POOL_ENTRIES = 'DEFAULT_POOL_ENTRIES';
const ADD_ENTRE_ROW = 'ADD_ENTRE_ROW';
const REMOVE_ENTRE_ROW = 'REMOVE_ENTRE_ROW';
const CLEAR_ACTIVE_ENTRIES = 'CLEAR_ACTIVE_ENTRIES';
const CHANGE_BONUS_SELECTOR = 'CHANGE_BONUS_SELECTOR';
const CHANGE_JACKPOT_SELECTOR = 'CHANGE_JACKPOT_SELECTOR';
const CHANGE_VOID_SELECTOR = 'CHANGE_VOID_SELECTOR';

export const poolEntriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_POOL_ENTRIES:
      return {
        ...state,
        entries: action.payload.map(action => ({
          id: action.id,
          rank: action.rank,
          origin: action.origin,
          player: action.player,
          submitted: action.submitted,
          lastEdit: action.lastEdit,
          answers: action.answers,
          score: action.score,
          isJackpot: action.isJackpot,
          isBonus: action.isBonus,
          totalAmount: action.totalAmount,
          isVoided: action.isVoided,
          playerCurrencyCode: action.playerCurrencyCode,
          playerTotalAmount: action.playerTotalAmount,
        })),
      };

    case DEFAULT_POOL_ENTRIES:
      return { ...initialState };

    case ADD_ENTRE_ROW:
      return { ...state, activeRows: [ ...state.activeRows, action.payload ] };

    case REMOVE_ENTRE_ROW:
      return {
        ...state,
        activeRows: state.activeRows.filter(row => row !== action.payload),
      };

    case CLEAR_ACTIVE_ENTRIES:
      return { ...state, activeRows: [] };

    case CHANGE_BONUS_SELECTOR:
      return {
        ...state,
        entries: state.entries.map(entre => {
          if (action.payload.includes(entre.id)) return { ...entre, isBonus: true };
          return entre;
        }),
      };

    case CHANGE_JACKPOT_SELECTOR:
      return {
        ...state,
        entries: state.entries.map(entre => {
          if (action.payload.includes(entre.id)) return { ...entre, isJackpot: true };
          return entre;
        }),
      };

    case CHANGE_VOID_SELECTOR:
      return {
        ...state,
        entries: state.entries.map(entre => {
          if (action.payload.includes(entre.id)) return { ...entre, isVoided: true };
          return entre;
        }),
      };

    default:
      return state;
  }
};

export const changePoolEntriesAction = payload => ({
  type: CHANGE_POOL_ENTRIES,
  payload,
});

export const removePoolEntriesAction = () => ({
  type: DEFAULT_POOL_ENTRIES,
});

export const addEntreRowAction = payload => ({
  type: ADD_ENTRE_ROW,
  payload,
});

export const removeEntreRowAction = payload => ({
  type: REMOVE_ENTRE_ROW,
  payload,
});

export const clearActiveEntriesAction = () => ({
  type: CLEAR_ACTIVE_ENTRIES,
});

export const changeBonusSelectorAction = payload => ({
  type: CHANGE_BONUS_SELECTOR,
  payload,
});

export const changeJackpotSelectorAction = payload => ({
  type: CHANGE_JACKPOT_SELECTOR,
  payload,
});

export const changeVoidSelectorAction = payload => ({
  type: CHANGE_VOID_SELECTOR,
  payload,
});
