import React from 'react';

import { Grid } from 'semantic-ui-react';

import { FeedQuestionSearch } from './FeedQuestionSearch';
import { FeedQuestionTable } from './FeedQuestionTable';
import './FeedQuestion.scss';

export const FeedQuestion = () => (
  <Grid className="feed-question-wrapper">
    <Grid.Column width="4">
      <FeedQuestionSearch />
    </Grid.Column>
    <Grid.Column width="12">
      <FeedQuestionTable />
    </Grid.Column>
  </Grid>
);
