import { composeWithDevTools } from '@redux-devtools/extension';
import { combineReducers, createStore } from 'redux';

import { poolEntriesReducer } from './reducers/poolEntries';
import { poolInfoReducer } from './reducers/poolInfo';
import { poolStatisticReducer } from './reducers/poolStatistic';
import { poolTableReducer } from './reducers/poolTable';
import { poolVariablesReducer } from './reducers/poolVariables';
import { feedQuestionSearchReducer } from './reducers/searchStateFeedQuestions';

const rootReducer = combineReducers({
  variables: poolVariablesReducer,
  poolInfo: poolInfoReducer,
  poolStatistic: poolStatisticReducer,
  poolEntries: poolEntriesReducer,
  feedQuestionSearch: feedQuestionSearchReducer,
  poolTable: poolTableReducer,
});

export const store = createStore(rootReducer, composeWithDevTools());
