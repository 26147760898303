import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

import { useAxiosRequest } from '../../../components/use-axios-request/use-axios-request.es';
import { API_ENTRY_UPDATE, ENTRIES_BONUS_OPTIONS, POOL_STATUS_ENUM } from '../../../constants';
import { clearActiveEntriesAction } from '../../../store/reducers/poolEntries';
import { ReduxStateType } from '../../../types';
import { updateEntreStatus, updatePoolEntries } from '../../../utils';
import './EntriesBonusSelector.css';

export const EntriesBonusSelector = () => {
  const dispatch = useDispatch();
  const { actualId: poolId } = useSelector((state: ReduxStateType) => state.variables);
  const { status, prizeJackpot } = useSelector((state: ReduxStateType) => state.poolInfo);
  const { activeRows } = useSelector((state: ReduxStateType) => state.poolEntries);

  const { update } = useAxiosRequest(null, {
    onError: data => toast.error(data.message),
  });

  const disableButton = React.useMemo(() => {
    if (status === POOL_STATUS_ENUM.COMPLETED) {
      return {
        BONUS: false,
        JACKPOT: Number(prizeJackpot) === 0,
        VOID: false,
      };
    }
    if (status === POOL_STATUS_ENUM.OPEN || status === POOL_STATUS_ENUM.LIVE) {
      return {
        BONUS: true,
        JACKPOT: true,
        VOID: false,
      };
    }
    if (status === POOL_STATUS_ENUM.SETTLED) {
      return {
        BONUS: true,
        JACKPOT: true,
        VOID: false,
      };
    }

    return {
      BONUS: true,
      JACKPOT: true,
      VOID: true,
    };
  }, [ status, prizeJackpot ]);

  const buttonHandler = action => {
    update({
      method: 'put',
      url: API_ENTRY_UPDATE,
      data: {
        set: true,
        action: action,
        entryIds: activeRows,
      },
    });
    dispatch(clearActiveEntriesAction());
    updateEntreStatus({ activeRows, action, dispatch });
    setTimeout(() => updatePoolEntries({ poolId, dispatch }), 3000);
  };

  return (
    <div className={'entries-change-button'}>
      <Button.Group size={'medium'} basic>
        {ENTRIES_BONUS_OPTIONS.map(({ label, action, className }) => (
          <Button
            key={action}
            className={`entries-change-button-${className}`}
            disabled={disableButton[action]}
            onClick={() => buttonHandler(action)}
          >
            {label}
          </Button>
        ))}
      </Button.Group>
    </div>
  );
};
