import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { ENTRIES, QUESTIONS } from '../constants';

export const MenuPoolPage = React.memo(() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isEntries = /entries/g.test(pathname);
  const [ tab, setTab ] = React.useState(isEntries ? ENTRIES : null);

  const questionsOnClick = () => {
    navigate('');
    setTab(null);
  };

  const entriesOnClick = () => {
    navigate(ENTRIES.toLowerCase());
    setTab(ENTRIES);
  };

  return (
    <Menu className="pool-info__tab-menu" tabular secondary pointing>
      <Menu.Item active={!tab} onClick={questionsOnClick}>
        {QUESTIONS}
      </Menu.Item>
      <Menu.Item active={tab === ENTRIES} onClick={entriesOnClick}>
        {ENTRIES}
      </Menu.Item>
    </Menu>
  );
});
