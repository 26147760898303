import {
  QUESTION_MULTIPLY,
  QUESTION_NEAREST_WIN_OUTCOME,
  QUESTION_NEAREST_WIN_RANGE,
  QUESTION_TIEBREAK
} from '../../constants';
import { PoolInfoType } from '../../types';

const initialState: PoolInfoType = {
  brand: '',
  originIds: [],
  names: [
    {
      lang: '',
      text: '',
    }
  ],
  descriptions: [
    {
      lang: '',
      text: '',
    }
  ],
  numberOfPlaces: 3,
  prizeFundGuarantee: '0',
  openDate: '',
  maxEntriesPerUser: '',
  maxEntriesPerPool: '',
  prizeDistribution: [ '50', '35', '15' ],
  prizeJackpot: '0',
  prizeBonusIndividual: '0',
  prizeBonusTotal: '0',
  entryBet: '',
  insuranceCommission: '0',
  commission: '',
  questions: [],
  currencyCode: '',
  prizeGuarantee: '0',
  status: '',
  startDate: null,
  id: null,
  brandId: null,
  createDate: null,
  createdBy: null,
  settleDate: null,
  settledBy: null,
  liveDate: null,
  lastUpdateDate: null,
  updatedBy: null,
  name: null,
  description: null,
  operatorFundShortfall: false,
  isDoubleScore: true,
  isVisibleToUnregistered: false,
  isPoolWithMultiplyChoice: false,
};

const CHANGE_POOL_INFO = 'CHANGE_POOL_INFO';
const DEFAULT_POOL_INFO = 'DEFAULT_POOL_INFO';
const CHANGE_POOL_STATUS = 'CHANGE_POOL_STATUS';
const CHANGE_POOL_OPEN_DATE = 'CHANGE_POOL_OPEN_DATE';

export const poolInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_POOL_INFO:
      return {
        ...state,
        numberOfPlaces: action.payload.numberOfPlaces,
        brand: action.payload.brand,
        startDate: action.payload.startDate,
        prizeGuarantee: action.payload.prizeGuarantee,
        id: action.payload.id,
        brandId: action.payload.brandId,
        commission: action.payload.commission,
        createDate: action.payload.createDate,
        createdBy: action.payload.createdBy,
        settleDate: action.payload.createDate,
        settledBy: action.payload.settledBy,
        liveDate: action.payload.liveDate,
        questions: action.payload.questions,
        isPoolWithMultiplyChoice: action.payload.questions.some(question => {
          return [
            QUESTION_MULTIPLY,
            QUESTION_TIEBREAK,
            QUESTION_NEAREST_WIN_OUTCOME,
            QUESTION_NEAREST_WIN_RANGE
          ].includes(question.questionType);
        }),
        prizeFundGuarantee: action.payload.prizeFundGuarantee,
        lastUpdateDate: action.payload.lastUpdateDate,
        updatedBy: action.payload.updatedBy,
        name: action.payload.name,
        description: action.payload.description,
        names: action.payload.names,
        descriptions: action.payload.descriptions,
        originIds: action.payload.originIds,
        openDate: action.payload.openDate,
        maxEntriesPerUser: action.payload.maxEntriesPerUser,
        maxEntriesPerPool: action.payload.maxEntriesPerPool,
        prizeDistribution: action.payload.prizeDistribution,
        prizeJackpot: action.payload.prizeJackpot,
        prizeBonusIndividual: action.payload.prizeBonusIndividual,
        prizeBonusTotal: action.payload.prizeBonusTotal,
        entryBet: action.payload.entryBet,
        insuranceCommission: action.payload.insuranceCommission,
        operatorFundShortfall: action.payload.operatorFundShortfall,
        isDoubleScore: action.payload.isDoubleScore,
        isVisibleToUnregistered: action.payload.isVisibleToUnregistered,
        status: action.payload.status,
        currencyCode: action.payload.currencyCode,
      };
    case DEFAULT_POOL_INFO:
      return { ...initialState };
    case CHANGE_POOL_STATUS:
      return { ...state, status: action.payload };
    case CHANGE_POOL_OPEN_DATE:
      return { ...state, openDate: action.payload };
    default:
      return state;
  }
};

export const changePoolInfoAction = payload => ({
  type: CHANGE_POOL_INFO,
  payload,
});

export const changePoolStatusAction = payload => ({
  type: CHANGE_POOL_STATUS,
  payload,
});

export const changePoolOpenDateAction = payload => ({
  type: CHANGE_POOL_OPEN_DATE,
  payload,
});

export const defaultPoolInfoAction = () => ({
  type: DEFAULT_POOL_INFO,
});
