import React from 'react';

import axios from 'axios';
import { debounce } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input } from 'semantic-ui-react';

import { API_FIND_BY_ID, KEY_CODE_OF_ENTER } from '../../constants';

export const GoToPoolComponent = () => {
  const navigate = useNavigate();

  const [ value, setValue ] = React.useState('');
  const [ isPoolCorrect, setIsPoolCorrect ] = React.useState(false);

  const sendRequest = React.useCallback((value: string) => {
    const config = {
      method: 'GET',
      url: API_FIND_BY_ID,
      params: { id: value },
    };
    axios(config)
      .then(res => res.data)
      .then(data => data.result)
      .then(() => setIsPoolCorrect(true))
      .catch(() => setIsPoolCorrect(false));
  }, []);

  const debouncedChangeHandler = React.useMemo(() => {
    return debounce(sendRequest, 1000);
  }, [ sendRequest ]);

  function poolIdFilterOnChange(e) {
    e.preventDefault();
    setIsPoolCorrect(false);

    const newValue = e.target.value;
    const isValid = !isNaN(parseFloat(newValue)) || !newValue;

    if (!isValid) return;
    setValue(newValue);

    const makeRequest = newValue.length > 2;
    if (makeRequest) debouncedChangeHandler(newValue);
  }

  function poolIdFilterOnKeyDown(e) {
    if (e.keyCode === KEY_CODE_OF_ENTER && isPoolCorrect) {
      navigate(`/ipools/${value}`);
    }
  }

  return (
    <Input
      action={!!value}
      value={value}
      onChange={poolIdFilterOnChange}
      onKeyDown={poolIdFilterOnKeyDown}
      placeholder="Go to pool"
    >
      <input />
      {!!value && (
        <Button
          icon="arrow right"
          as={Link}
          to={`/ipools/${value}`}
          color={isPoolCorrect ? 'green' : null}
          disabled={!isPoolCorrect}
        />
      )}
    </Input>
  );
};
