import React from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Header, Icon } from 'semantic-ui-react';

import { IpoolsTable } from './IpoolsTable';
import { IpoolsTableMenu } from './IpoolsTableMenu';
import { API_POOL_LIST, POOL_ORIGINS_ALL, POOL_STATUS_ALL } from '../../constants';
import { defaultPoolInfoAction } from '../../store/reducers/poolInfo';
import { changePoolListAction } from '../../store/reducers/poolTable';
import { changeIdAction } from '../../store/reducers/poolVariables';
import { ReduxStateType } from '../../types';
import './IpoolsTablePage.scss';

export const IpoolsTablePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state: ReduxStateType) => state);
  const { queryStatus, queryOriginId, querySearch } = state.poolTable;

  React.useEffect(() => {
    const originId = queryOriginId === POOL_ORIGINS_ALL ? null : queryOriginId;
    const status = queryStatus === POOL_STATUS_ALL ? null : queryStatus;

    axios
      .get(API_POOL_LIST, {
        params: {
          originId,
          status,
          name: querySearch,
        },
      })
      .then(response => response.data)
      .then(data => {
        dispatch(changePoolListAction(data.results));
      })
      .catch(error => toast.error(error.message));
    // eslint-disable-next-line
  }, [queryOriginId, queryStatus, querySearch]);

  function createPool() {
    dispatch(defaultPoolInfoAction());
    dispatch(changeIdAction(0));
    navigate('/createPool');
  }

  return (
    <div className="ipools-container">
      <Header as="h1" className="ipools_header">
        Pools list
      </Header>
      <Button
        className="create-pool-button"
        labelPosition="left"
        color="green"
        onClick={createPool}
        icon
      >
        <Icon name="star" />
        Add new
      </Button>

      <IpoolsTableMenu />

      <div className="ipools-table-container">
        <IpoolsTable />
      </div>
    </div>
  );
};
