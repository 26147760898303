import React from 'react';

import Raven from 'raven-js';
import { Segment } from 'semantic-ui-react';

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { error: Error | null | undefined }
> {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    if (process.env.NODE_ENV === 'production') {
      Raven.captureException(error, { extra: errorInfo });
    }

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log(errorInfo);
    }
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <Segment inverted color="red">
          {error.message}
        </Segment>
      );
    }

    return this.props.children;
  }
}
