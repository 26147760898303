import React from 'react';

import { Button, Grid, Header, Input, Label } from 'semantic-ui-react';

import { CreationFormField } from './edit-pool-form/CreationFormField';
import {
  ADD_BUTTON,
  IS_CASH,
  KEY_CODE_OF_ENTER,
  KEY_CODE_OF_TAB,
  NUMBER_OF_PLACES,
  PRIZE_CASH_DISTRIBUTION_FIELD,
  PRIZE_DISTRIBUTION_FIELD,
  PRIZE_GUARANTEE_FIELD,
  TOGGLE
} from '../constants';

export const PrizePlacesComponent = ({
  prizes,
  formState,
  setFormState,
  error,
  disableNotDraftStatus,
  isCash,
  setIsCash,
  setErrorFieldsState,
}: {
  prizes: Array<string | number>;
  formState: any;
  setFormState: any;
  error: boolean;
  disableNotDraftStatus?: boolean;
  isCash: boolean;
  setIsCash: (prev: (prev) => boolean) => void;
  setErrorFieldsState: (p: (prev) => any[]) => void;
}) => {
  const totalPrizes = prizes.reduce((sum, el) => Number(sum) + Number(el));
  const cashList = formState[PRIZE_CASH_DISTRIBUTION_FIELD];
  const pricesRefCollection = React.useRef([]);

  function handleChange(e, { name, value, id }) {
    const updatedList = formState[name];
    updatedList[id] = value;
    setFormState({
      ...formState,
      [name]: updatedList,
    });
  }

  function cashOnChange(e, index) {
    const value = e.target.value;

    setFormState(prev => {
      const updatingCashList = prev[PRIZE_CASH_DISTRIBUTION_FIELD];
      updatingCashList[index] = value;

      let updatingList;

      if (!formState[PRIZE_GUARANTEE_FIELD] || formState[PRIZE_GUARANTEE_FIELD] == 0) {
        const prizeFund = updatingCashList.reduce((a, c) => Number(a) + Number(c));
        updatingList = prev[PRIZE_DISTRIBUTION_FIELD].map((el, i) => {
          const item = updatingCashList[i] || 0;
          const percent = (item / prizeFund) * 100;

          return Number.isInteger(percent) ? percent : percent.toFixed(2);
        });
      } else {
        const prizeFund = formState[PRIZE_GUARANTEE_FIELD];
        updatingList = prev[PRIZE_DISTRIBUTION_FIELD];
        const percent = (value / prizeFund) * 100;

        updatingList[index] = Number.isInteger(percent) ? percent : percent.toFixed(2);
      }

      return {
        ...prev,
        [PRIZE_DISTRIBUTION_FIELD]: updatingList,
        [PRIZE_CASH_DISTRIBUTION_FIELD]: updatingCashList,
      };
    });
  }

  function deleteInput(id) {
    setFormState(prev => {
      const updatedPercent = prev[PRIZE_DISTRIBUTION_FIELD].filter((el, i) => i !== id);
      const updatedCashList = prev[PRIZE_CASH_DISTRIBUTION_FIELD].filter((el, i) => i !== id);
      return {
        ...prev,
        [PRIZE_DISTRIBUTION_FIELD]: updatedPercent,
        [PRIZE_CASH_DISTRIBUTION_FIELD]: updatedCashList,
        [NUMBER_OF_PLACES]: updatedPercent.length,
      };
    });
  }

  function addPrizePlace() {
    setFormState(prev => {
      const updatedList = [ ...prev[PRIZE_DISTRIBUTION_FIELD], '0' ];
      const updatedCashList = [ ...prev[PRIZE_CASH_DISTRIBUTION_FIELD], '' ];
      return {
        ...prev,
        [PRIZE_DISTRIBUTION_FIELD]: updatedList,
        [PRIZE_CASH_DISTRIBUTION_FIELD]: updatedCashList,
        [NUMBER_OF_PLACES]: updatedList.length,
      };
    });
  }

  function cashToggleOnChange() {
    if (!isCash) {
      setFormState(prev => {
        const updatedList = [ ...prev[PRIZE_DISTRIBUTION_FIELD] ];
        updatedList.fill('');
        return {
          ...prev,
          [PRIZE_DISTRIBUTION_FIELD]: updatedList,
        };
      });
      if (!formState[PRIZE_GUARANTEE_FIELD] || formState[PRIZE_GUARANTEE_FIELD] == 0) {
        setErrorFieldsState(prev => [ ...prev, PRIZE_GUARANTEE_FIELD ]);
      }
    } else {
      setFormState(prev => {
        const updatedCashList = prev[PRIZE_CASH_DISTRIBUTION_FIELD].fill('');
        return { ...prev, [PRIZE_CASH_DISTRIBUTION_FIELD]: updatedCashList };
      });
      setErrorFieldsState(prev => prev.filter(el => el !== PRIZE_GUARANTEE_FIELD));
    }
    setIsCash(prev => !prev);
  }

  function addRef(el, array) {
    if (el && !array?.includes(el)) {
      array.push(el);
    }
  }

  function onKeyDownPrice(e, index) {
    if (e.keyCode === KEY_CODE_OF_TAB || e.keyCode === KEY_CODE_OF_ENTER) e.preventDefault();
    const nextCondition =
      ((e.keyCode === KEY_CODE_OF_TAB && !e.shiftKey) || e.keyCode === KEY_CODE_OF_ENTER) &&
      index + 1 !== prizes.length;
    const prevCondition = e.shiftKey && e.keyCode === KEY_CODE_OF_TAB && index !== 0;
    if (nextCondition) {
      (
        pricesRefCollection.current[index + 1].children[isCash ? 2 : 1].firstElementChild as HTMLElement
      )?.focus();
    } else if (prevCondition) {
      (
        pricesRefCollection.current[index - 1].children[isCash ? 2 : 1].firstElementChild as HTMLElement
      )?.focus();
    }
  }

  return (
    <>
      <CreationFormField
        label="Cash"
        type={TOGGLE}
        name={IS_CASH}
        inputWidth={6}
        labelWidth={3}
        handleChange={cashToggleOnChange}
        checked={isCash}
        disabled={disableNotDraftStatus}
      />
      {prizes.map((prize, index) => (
        <div key={index} className="prize-place" ref={el => addRef(el, pricesRefCollection.current)}>
          <Header className="field-label prize-place__label" textAlign="center" as="h5">
            {`${index + 1} place`}
          </Header>
          <Input
            id={String(index)}
            className="small-input-field prize-place__percent"
            onChange={handleChange}
            name={PRIZE_DISTRIBUTION_FIELD}
            value={prize}
            disabled={disableNotDraftStatus || isCash}
            icon="percent"
            placeholder={`${index + 1} place`}
            error={error}
            onKeyDown={e => onKeyDownPrice(e, index)}
          />

          {isCash && (
            <Input
              className="prize-place__cash"
              value={cashList[index]}
              onChange={e => cashOnChange(e, index)}
              onKeyDown={e => onKeyDownPrice(e, index)}
            />
          )}

          {index > 0 && (
            <Button icon="delete" onClick={() => deleteInput(index)} disabled={disableNotDraftStatus} />
          )}
        </div>
      ))}
      <CreationFormField
        label="Add place"
        type={ADD_BUTTON}
        inputWidth={6}
        labelWidth={3}
        name="addButton"
        handleChange={addPrizePlace}
        disabled={disableNotDraftStatus}
      />
      {!disableNotDraftStatus && (
        <Grid verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column width={3} className="field-label-wrapper">
              <Header className="field-label" textAlign="center" as="h5">
                Total
              </Header>
            </Grid.Column>
            <Grid.Column width={6}>
              <Label color={totalPrizes !== 100 ? 'red' : 'green'}>{totalPrizes}</Label>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};
