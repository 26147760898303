import React from 'react';

import axios from 'axios';
import { Formik } from 'formik';
import { ToastContainer } from 'react-toastify';
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react';
import { object, string } from 'yup';

import { UserType } from '../types';

const LoginSchema = object().shape({
  username: string().required(),
  password: string().required(),
  originId: string().required(),
});

export const LoginForm = ({
  onLogin,
}: {
  onLogin: (user: UserType) => void;
}) => (
  <div className="login-form">
    {/*
      Heads up! The styles below are necessary for the correct render of this example.
      You can do same with CSS, the main idea is that all the elements up to the `Grid`
      below must have a height of 100%.
    */}
    <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
    <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="red" textAlign="center">
          Welcome to iPools
        </Header>
        <Formik
          validationSchema={LoginSchema}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              const { data } = await axios.post('/v1/DealerAPI/login', values, {
                // timeout: 3000
              });
              setSubmitting(false);
              onLogin({
                jwt: data.result.jwt,
                username: values.username,
                originId: data.result.originId,
                permissions: data.result.permissions,
              });
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error);
              setFieldError('submit', error.message);
              setSubmitting(false);
            }
          }}
          initialValues={{
            username: '',
            password: '',
            originId: '1',
          }}
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            isValid,
            isSubmitting,
            errors,
          }: {
            handleSubmit: any;
            values: any;
            setFieldValue: any;
            isValid: any;
            isSubmitting: any;
            errors: any;
          }) => (
            <Form onSubmit={handleSubmit} error={!isValid} size="large">
              <Segment>
                <Form.Input
                  name="username"
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="login"
                  value={values.username}
                  onChange={(e, { name, value }) => setFieldValue(name, value)}
                />
                <Form.Input
                  name="password"
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  value={values.password}
                  onChange={(e, { name, value }) => setFieldValue(name, value)}
                />
                {errors.submit && <Message error content={errors.submit} />}
                <Button
                  color="red"
                  fluid
                  size="large"
                  disabled={!isValid}
                  loading={isSubmitting}
                  type="submit"
                >
                  Login
                </Button>
              </Segment>
            </Form>
          )}
        </Formik>
      </Grid.Column>
    </Grid>
    <ToastContainer />
  </div>
);
