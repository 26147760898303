import React from 'react';

import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import { getStatus } from './helpers';
import { IPOOLS_DATE_FORMAT, POOL_STATUS_ENUM } from '../../constants';
import { changeIdAction } from '../../store/reducers/poolVariables';
import { PoolType } from '../../types';
import { formattingDate } from '../../utils';

export const IpoolsTableRow = ({ pool }: { pool: PoolType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = getStatus(pool.status);
  const formattedStatus = status[0] + status.slice(1).toLowerCase();
  const origins = pool.origins?.map(o => o.description).join(', ') || '-';

  const formattedSettleDate = formattingDate(
    pool.status === POOL_STATUS_ENUM.SETTLED && pool.lastUpdateDate,
    IPOOLS_DATE_FORMAT
  );
  const formattedStartBettingDate = formattingDate(pool.openDate, IPOOLS_DATE_FORMAT);
  const formattedEndBettingDate = formattingDate(pool.liveDate, IPOOLS_DATE_FORMAT);
  const formattedCreateDate = formattingDate(pool.createDate, IPOOLS_DATE_FORMAT);
  const formattedLastUpdateDate = formattingDate(pool.lastUpdateDate, IPOOLS_DATE_FORMAT);

  function copyPool() {
    dispatch(changeIdAction(pool.id));
    navigate('/createPool');
  }

  return (
    <Table.Row>
      <Table.Cell>{pool.id ? pool.id : '-'}</Table.Cell>
      <Table.Cell>
        <p className={'pool-table__name-cell'}>
          <Link to={`/ipools/${pool.id}`}>{pool.name}</Link>
        </p>
      </Table.Cell>
      <Table.Cell>{formattedStatus}</Table.Cell>
      <Table.Cell>
        <p className={'pool-table__origins-cell'}>{origins}</p>
      </Table.Cell>
      <Table.Cell>{formattedStartBettingDate}</Table.Cell>
      <Table.Cell>{formattedEndBettingDate}</Table.Cell>
      <Table.Cell>{formattedSettleDate}</Table.Cell>
      <Table.Cell>
        {formattedCreateDate}
        <br />
        {pool.createdBy || '-'}
      </Table.Cell>
      <Table.Cell>
        {formattedLastUpdateDate}
        <br />
        {pool.updatedBy || ''}
      </Table.Cell>
      <Table.Cell>
        <Icon className="ipools-table__btn-copy-pool" name="clone" onClick={copyPool} />
      </Table.Cell>
    </Table.Row>
  );
};
