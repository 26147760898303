import React from 'react';

import { useDispatch } from 'react-redux';
import { Button, Header, List } from 'semantic-ui-react';

import {
  FeedQuestionSearchInput,
  FeedQuestionsStartAndEndDate,
  LeagueDropdownWithAxios,
  RegionDropdownWithAxios,
  SportsDropdownWithAxios
} from './FeedQuestionsComponents/FeedQuestionsComponents';
import { defaultSearchState } from '../../../../store/reducers/searchStateFeedQuestions';

export const FeedQuestionSearch = () => {
  const dispatch = useDispatch();
  return (
    <List>
      <List.Item>
        <Button
          fluid
          floated="right"
          icon="delete"
          content="Clear"
          onClick={() => {
            dispatch(defaultSearchState());
          }}
        />
      </List.Item>
      <List.Item>
        <FeedQuestionSearchInput />
      </List.Item>
      <List.Item>
        <FeedQuestionsStartAndEndDate />
      </List.Item>
      <List.Item>
        <Header as="h5" style={{ marginBottom: 10 }}>
          Sports / Regions / Leagues
        </Header>
        <SportsDropdownWithAxios />
      </List.Item>
      <List.Item>
        <RegionDropdownWithAxios />
      </List.Item>
      <List.Item>
        <LeagueDropdownWithAxios />
      </List.Item>
    </List>
  );
};
